import { acceptHMRUpdate, defineStore } from 'pinia'
import contactService from '@/services/contact.service'

export const useContactStore = defineStore({
  id: 'contactStore',
  state: () => ({

  }),
  setup () {},
  actions: {
    sendMessage (data) {
      data.phone = '-'
      return contactService.sendMessage(data)
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useContactStore, import.meta.hot))
}
