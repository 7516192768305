import { acceptHMRUpdate, defineStore } from 'pinia'
import programsService from '@/services/programs.service'
import { useRoute } from 'vue-router'

export const useProgramStore = defineStore({
  id: 'programStore',
  state: () => ({
    filter: {
      status: 'active'
    },
    base_events: [],
    active_event_title: '',
    active_event_cover_subject: '',
    active_event_subject: '',
    active_event_award_title: '',
    active_event_award_cover_description: '',
    active_event_award_description: '',
    route: useRoute()
  }),
  setup () {},
  actions: {
    getPrograms (status) {
      const tag = this.route.query.tag
      return programsService.getPrograms(status, tag).then(
        response => {
          console.log(response)
          this.base_events = response.data.data.base_events
          return response
        }
      )
    },
    setActiveEventTitle (title) {
      this.active_event_title = title
    },
    setActiveEventCoverSubject (subject) {
      this.active_event_cover_subject = subject
    },
    setActiveEventSubject (subject) {
      this.active_event_subject = subject
    },
    setActiveEventAwardDescriptionTitle (title) {
      if (title !== null && title !== undefined && title !== '') {
        this.active_event_award_title = title
      } else {
        this.active_event_award_title = ''
      }
    },
    setActiveEventAwardCoverDescription (description) {
      if (description !== null && description !== undefined && description !== '') {
        this.active_event_award_cover_description = description
      } else {
        this.active_event_award_cover_description = ''
      }
    },
    setActiveEventAwardDescription (description) {
      if (description !== null && description !== undefined && description !== '') {
        this.active_event_award_description = description
      } else {
        this.active_event_award_description = ''
      }
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProgramStore, import.meta.hot))
}
