import { acceptHMRUpdate, defineStore } from 'pinia'
import BlogService from '@/services/blog.service'

export const useBlogStore = defineStore({
  id: 'blogStore',
  state: () => ({
    getData: true,
    paging: {
      page: 0,
      length: 12
    },
    posts: [],
    popular_posts: [],
    latestPosts: [],
    post: null
  }),
  setup () {},
  actions: {
    resetPaging () {
      this.getData = true
      this.paging.page = 0
    },
    getPosts (filter) {
      if (this.getData) {
        this.paging.page = this.paging.page + 1
        return BlogService.getPosts(filter, this.paging).then(
          response => {
            if (this.paging.page === 1) {
              this.posts = response.data.data.blog_list
            } else {
              this.posts = [].concat.apply(this.posts, response.data.data.blog_list)
            }
            if (response.data.data.blog_list.length < this.paging.length) {
              this.getData = false
            }
            return response
          }
        )
      }
    },
    getPopularPosts (filter) {
      return BlogService.getPopularPosts(filter).then(
        response => {
          this.popular_posts = response.data.data.blog_list
          return response
        }
      )
    },
    getLatestPosts () {
      return BlogService.getRecentPosts().then(
        response => {
          this.latestPosts = response.data.data.blog_list
          return response
        }
      )
    },
    getSingle (slug) {
      return BlogService.getSingle(slug).then(
        response => {
          this.post = response.data.data.blog
          return response
        }
      )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBlogStore, import.meta.hot))
}
