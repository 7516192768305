<template>
  <button v-if="applyStore.isFeedProgram" class="btn btn-primary button_1 d-flex align-items-center justify-content-center" style="height:37px !important;color:#402661 !important;" @click="onPreviewClick()" >Önizleme</button>
   <BSModal ref="refModal">
<template #header>
</template>
<template #body>
    <div class="d-flex align-items-center justify-content-center">
        <div v-if="applyStore.preview">
            <div class="text-start">
            <p class="font-size-2xl black">
            Fikrin Önizlemesi
            </p>
            </div>
            <wall-post :data="applyStore.preview"  :likeBox="true" :commentBox="true"/>
            <div class="d-flex">
            <button @click="onSendClick()" class="btn px-5 me-2 button_4">Gönder</button>
            <button @click="onCancelClick()" class="btn button_5 px-5">Vazgeç</button>
            </div>
        </div>
    </div>
</template>
<template #footer>
</template>
  </BSModal>
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useApplyStore } from '../../store/apply'
import WallPost from '../../components/bonapply/WallPost'

export default defineComponent({
  name: 'ApplyPreview',
  props: {
    applyId: null
  },
  data () { return {} },
  setup (props, context) {
    const refModal = ref(null)
    const applyStore = useApplyStore()

    onMounted(() => {

    })

    const onPreviewClick = () => {
      refModal.value.show()
      applyStore.getSingle(props.applyId)
    }

    const onCancelClick = () => {
      refModal.value.close()
    }

    const onSendClick = (event) => {
      refModal.value.close()
      context.emit('onSendClick', {})
    }

    return {
      refModal,
      onPreviewClick,
      applyStore,
      onCancelClick,
      onSendClick
    }
  },
  components: {
    WallPost
  }
})
</script>
<style lang="scss" scoped>
@import "src/styles/media_query.scss";
@import 'src/styles/vakifbank-style.scss';
</style>
