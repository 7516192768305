<template>
<div class="comments-root">
    <div class="row mt-3">
        <div class="col-12">
            <p class="m-0">
                <button @click="onCommentsButtonClick" class="btn border-0 p-0 black font-size-base" type="button" data-bs-toggle="collapse" :data-bs-target="'#post-'+rootEntityId+'-comments'" aria-expanded="false" :aria-controls="'post-'+rootEntityId+'-comments'">Yorumları Göster<i class="fa-solid fa-angle-down ms-2 black"></i></button>
            </p>
            <div class="collapse" :id="'post-'+rootEntityId+'-comments'">
                <div class="mb-3">
                <comment-input parentEntity="Applies" :parentEntityId="rootEntityId" show="true" @onWriteCommentComplete="{}"/>
                </div>
                <comments-indent :comments="commentStore.comments['#/RootOf'+props.parentEntity+'_'+props.rootEntityId]" :isRoot="true"/>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { defineComponent } from 'vue'
import { useCommentStore } from '../../store/comment'
import CommentInput from '../../components/bonapply/CommentInput'
import CommentsIndent from '../../components/bonapply/CommentsIndent'

export default defineComponent({
  name: 'CommentsRoot',
  props: {
    parentEntity: null,
    rootEntityId: null
  },
  data () { return {} },
  setup (props) {
    const commentStore = useCommentStore()

    const onCommentsButtonClick = () => {
      const data = {
        parentEntity: props.parentEntity,
        parentEntityId: props.rootEntityId
      }
      commentStore.get(data)
    }

    return {
      commentStore,
      onCommentsButtonClick,
      props
    }
  },
  components: {
    CommentInput,
    CommentsIndent
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>
@import 'src/styles/bs5_theme.scss';
</style>
