<template>
<div v-for="comment in comments" :key="comment.id" :class="isRoot ? '' : 'border-right-0'">
  <div class="comments-indent">
    <div class="card card-body mb-2 pe-0 pb-1">
      <comment-indent-item :comment="comment" :isRoot="isRoot"/>
      <comments-indent :comments="comment.children"/>
    </div>
  </div>
</div>
</template>
<script>
import { defineComponent } from 'vue'
import CommentsIndent from '../../components/bonapply/CommentsIndent'
import CommentIndentItem from '../../components/bonapply/CommentIndentItem'

export default defineComponent({
  name: 'CommentItem',
  props: {
    comments: null,
    isRoot: null
  },
  data () { return {} },
  setup (props) {
    return {

    }
  },
  components: {
    CommentsIndent,
    CommentIndentItem
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>
@import 'src/styles/bs5_theme.scss';
</style>
