import { acceptHMRUpdate, defineStore } from 'pinia'
const BASE_URL = (process.env.VUE_APP_API_BASE_URL !== undefined) ? process.env.VUE_APP_API_BASE_URL : 'https://v3.bonapply.com/'

// const BASE_URL = 'https://v3.bonapply.com'
// const BASE_URL = 'https://127.0.0.1:8000'
// const BASE_URL = 'http://bonapply_v3.test'

export const useConfigStore = defineStore({
  id: 'configStore',
  state: () => ({
    downloadBasePath: BASE_URL + '/api/v1/file/download/',
    imageBasePath: BASE_URL + '/api/v1/file/show/'
  }),
  setup () {

  },
  actions: {

  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useConfigStore, import.meta.hot))
}
