<template>
  <layout-type-1>
    <div class="mb-4">
      <FormTextSlot form-text="Şifre Bilgileri"></FormTextSlot>
    </div>
    <Form></Form>
    <form @submit="onSubmit">
      <div class="form-outline mb-4 text-start">
        <label for="old_password" class="mb-1">Mevcut Şifre</label>
        <field name="old_password" placeholder="Mevcut Şifrenizi Yazınız" class="form-control" type="password" />
        <span>{{ errors.old_password }}</span>
      </div>

      <div class="form-outline mb-4 text-start">
        <label for="new_password" class="mb-1">Yeni Şifre</label>
        <field name="new_password" placeholder="Yeni Şifreniz" class="form-control" type="password" />
        <span>{{ errors.new_password }}</span>
        <span v-if="errorMessageField === 'password'">{{ errorMessage }}</span>
      </div>

      <div class="form-outline mb-4 text-start">
        <label for="new_password_again" class="mb-1">Yeni Şifre Tekrar</label>
        <field name="new_password_again" placeholder="Yeni Şifreniz Tekrar" class="form-control" type="password" />
        <span>{{ errors.new_password_again }}</span>
      </div>
      <div class="d-grid pt-1 mb-4 text-start col-4 offset-4">
        <ButtonSlot button-text="Güncelle"></ButtonSlot>
      </div>
    </form>
  </layout-type-1>
</template>
<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useForm, Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { useAuthStore } from '../../store/auth'
import LayoutType1 from '../../layouts/LayoutType1.vue'
import ButtonSlot from '../../layouts/ButtonSlot.vue'
import FormTextSlot from '../../layouts/FormTextSlot'

export default defineComponent({
  name: 'ProfileRenewPasswordView',
  data () { return {} },
  setup (props) {
    onMounted(() => {
      document.title = 'VakıfBank | Şifre Yenileme'
    })
    const authStore = useAuthStore()
    const errorMessage = ref(null)
    const errorMessageField = ref(null)
    const resetErrorMessage = () => {
      errorMessage.value = ''
      errorMessageField.value = ''
    }
    const schema = yup.object().shape({
      old_password: yup.string().required('Lütfen Mevcut Şifrenizi Giriniz'),
      new_password: yup.string().required('Lütfen Yeni Şifrenizi Giriniz'),
      new_password_again: yup.string().required('Lütfen Yeni Şifrenizi Tekrar Giriniz').oneOf([yup.ref('new_password'), null], 'Şifreler eşleşmiyor.')
    })

    const { handleSubmit, isSubmitting, errors } = useForm({
      validationSchema: schema
    })

    const onSubmit = handleSubmit((values, { resetForm }) => {
      resetErrorMessage()
      authStore.changePassword(values).then(
        response => {
          if (response.status === 200) {
            resetForm()
          } else {
            if (response.response.data.messageType === 'formInput') {
              errorMessage.value = response.response.data.message
              errorMessageField.value = response.response.data.messageField
            }
          }
        }
      )
    })

    return {
      authStore,
      onSubmit,
      isSubmitting,
      errors,
      errorMessage,
      errorMessageField
    }
  },
  components: {
    Form,
    Field,
    LayoutType1,
    ButtonSlot,
    FormTextSlot
  },
  methods: {

  }
})
</script>
