<template>
    <button type="submit" :disabled="isSubmitting" class="btn btn-primary btn-block button_default button_1">{{ buttonText }}</button>
</template>
<script>
export default {
  props: {
    buttonText: {
      type: String,
      default: () => 'Default'
    }
  }
}
</script>
