<template>
  <div class="wall-post mb-3">
    <div class="d-flex justify-content-between align-items-center">
      <div class="overflow-hidden word-break">
        <div class="mb-2 font-size-xxl">
            Başvuru Durumu: <strong> {{ apply.status.title }} </strong>
        </div>
        <div class="d-flex align-items-center">
            <div>
              <p class="m-0 me-2 dark-gray font-size-base" v-if="apply.completeDate">{{ apply.completeDate }} {{ apply.completeTime }}</p>
              <p class="m-0 me-2 dark-gray font-size-base" v-if="!apply.completeDate">{{ apply.updateDate }} {{ apply.updateTime }}</p>
            </div>
        </div>
        <div class="d-flex align-content-center">
          ID: {{ apply.uid }}
        </div>
      </div>
      <div class="d-flex justify-content-end down_md_left">
        <button class="btn btn-primary button_1 d-flex align-items-center justify-content-center" @click="redirectTotDetail()">
          Başvuruyu Gör
        </button>
      </div>
    </div>
  </div>
  <hr class="mb-3">
</template>
<script>
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'ApplyCart',
  props: {
    apply: {}
  },
  data () { return {} },
  setup (props) {
    const router = useRouter()
    const redirectTotDetail = () => {
      router.push({ name: 'apply', params: { applyUId: props.apply.uid } })
    }
    return {
      redirectTotDetail
    }
  },
  components: {
  },
  methods: {
  }
})
</script>
<style lang="scss" scoped>
@import 'src/styles/bs5_theme.scss';
@import 'src/styles/media_query.scss';
</style>
