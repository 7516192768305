<template>
       <div id="aboutUs" class="container mb-5">
        <div class="row" >
            <div class="col-xl-6 col-lg-6 col-12 ps-0 background-aboutUs down_md2_mb" v-bind:style="{ backgroundImage: 'url(' + section.coverImg + ')' }" style="background-size: cover;
            background-repeat: no-repeat !important;
            background-position: center !important;
            border-radius: 10px !important;
            min-height: 300px !important;">
            </div>
            <div class="col-xl-6 col-lg-6 col-12 m-auto">
                <div class="down_md2_mr_none" style="margin-right:6rem;">
                    <div v-if="landingInfo.data.content.pageKey == 'in_house_user_landing_page'" class="mb-3" style="text-align: justify;">
                        <p class="m-0 text-left font-size-lg yellow fw-bold line-height">
                            {{ section.title }}
                        </p>
                    </div>
                     <div v-if="landingInfo.data.content.pageKey == 'in_house_user_landing_page'" class="mb-2" style="text-align: justify;">
                        <p class="m-0 text-left font-size-xl purple fw-bold line-height">
                            {{ section.subTitle }}
                        </p>
                    </div>
                    <div v-if="landingInfo.data.content.pageKey == 'external_user_landing_page'">
                        <p class="m-0 text-left font-size-3xl purple fw-bold line-height down_md2_font3">
                            {{ section.title }}
                        </p>
                    </div>
                    <div>
                        <p class="m-0 text-left font-size-base purple" v-html="section.content" style="text-align: justify;"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    section: {},
    landingInfo: {}
  },
  setup (props) {}
})
</script>
<style lang="scss" scoped>
@import 'src/styles/media_query.scss';
@import 'src/styles/bs5_theme.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500;700&display=swap');
*{
    font-family: 'Roboto', sans-serif !important;
}
</style>
