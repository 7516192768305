<template>
    <div class="row mb-5">
        <div class="col-12 mb-3">
            <p class="m-0 font-size-base black fw-bold">Bildirimler</p>
        </div>

        <template v-for="notification in notificationStore.notifications" :key="notification">
        <div class="step completed">
            <div class="v-stepper">
            <div class="circle  d-flex align-items-center justify-content-center">
                <i class="fa-solid fa-circle-check font-size-xxs black"></i>
            </div>
            <div class="line"></div>
            </div>
            <div class="w-100 d-flex justify-content-between">
                <p style="width:80%;word-break: break-word !important;" class="font-size-sm black ms-2 me-2" v-html="notification.message"></p>
                <p style="width:30%;"  class="me-4 light-gray font-size-sm">{{ notification.date.date }}</p>
                <p style="width:10%;"  class="light-gray font-size-sm d-flex justify-content-end"> {{ notification.date.time }} </p>
            </div>
        </div>
        </template>
    </div>

    <div class="row d-none">
            <div class="col-12 mb-3">
                <p class="m-0 font-size-base dark-gray fw-bold">Geçmiş</p>
            </div>

            <div class="step passive d-flex justify-content-between">
            <div class="v-stepper">
            <div class="circle  d-flex align-items-center justify-content-center">
            <i class="fa-solid fa-xmark font-size-xxs black"></i>
            </div>
            <div class="line"></div>
            </div>
            <div class="content d-flex justify-content-between w-100">
                <p style="width:80%;word-break: break-word !important;" class="font-size-sm light-gray me-2">Ufuk Öztürk <strong>Fikriniz yayınlandı!</strong></p>
                <p style="width:30%;" class="me-4 light-gray font-size-sm">19.11.2022</p>
                <p style="width:10%;" class="light-gray font-size-sm  d-flex justify-content-end">10:12</p>
            </div>
            </div>

    </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useNotificationStore } from '../../store/notification'

export default defineComponent({
  name: 'CommonView',
  data () { return {} },
  setup () {
    onMounted(() => {
      notificationStore.resetPaging()
      const listElm = document.querySelector('html')
      window.addEventListener('scroll', e => {
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          if (keepScrolling.value) {
            keepScrolling.value = false
            setTimeout(function () {
              keepScrolling.value = true
            }, 500)
            getNotificationList()
          }
        }
      })
      getNotificationList()
    })
    const getNotificationList = () => {
      if (notificationStore.getData) {
        notificationStore.getNotifications()
      }
    }
    const notificationStore = useNotificationStore()
    const keepScrolling = ref(true)
    return {
      notificationStore,
      getNotificationList
    }
  },
  components: {

  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>
@import 'src/styles/media_query.scss';
@import 'src/styles/bs5_theme.scss';
</style>
