<template>
<div class="container">
    <div class="row mb-3">
      <div class="col-12 col-md-3">
        <left-list></left-list>
        </div>
          <div v-if="collaborateStore.partnershipRequestsToMe" class="col-12 col-md-9">
              <h5 v-if="collaborateStore.partnershipRequestsToMe && collaborateStore.partnershipRequestsToMe.length==0">Aktif bir ortaklık talebi bulunmuyor</h5>
              <div v-for="request in collaborateStore.partnershipRequestsToMe" :key="request">
                <common-demands-card :data="request" />
              </div>
          </div>
    </div>
</div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { useCollaborateStore } from '../../store/collaborate'
import LeftList from '../../components/bonapply/LeftList.vue'
import CommonDemandsCard from '../../components/bonapply/CommonDemandsCard'

export default defineComponent({
  name: 'PartnershipRequestsToMeView',
  data () { return {} },
  setup () {
    onMounted(() => {
      document.title = 'VakıfBank | Ortak Olma İstekleri'
      collaborateStore.getPartnershipRequestsToMe()
    })
    const collaborateStore = useCollaborateStore()
    return {
      collaborateStore
    }
  },
  components: {
    LeftList,
    CommonDemandsCard
  },
  methods: {

  }
})
</script>
