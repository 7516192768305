<template>
  <div class="container">
    <div class="row mb-5">
      <div class="col-12 mb-4 up_md2_ps_0">
      </div>
      <div class="col-xl-4 col-lg-4 col-12 down_sm_padding_1 div_hover mb-3" v-for="post in blogStore.posts" :key="post">
        <div class="border-0 hover-border rounded-10 p-4" style="background-color:#f8f8f8!important;">
          <!--
          <div class="mb-2" v-if="post.videoPath != ''">
            <video width="400" height="190" controls style="max-width: 100%" class="rounded-10">
              <source :src="post.videoPath" type="video/mp4">
            </video>
          </div>
          -->
          <div class="mb-2" v-if="post.coverImg != ''">
            <img :src="post.coverImg" class="img-fluid w-100 h-100 rounded-10">
          </div>
          <div class="d-block down_xl_height down_xl_mr_0">
            <h4 class="text-start px-0 font-size-xl mb-2  fw-bold active w-100" style="height:50px;overflow:hidden;color:#402661!important;">
              {{ post.title }}
            </h4>
            <div class="mb-2 font-size-lg thin_scrollbar" style="height:165px;overflow:auto !important; overflow-x: hidden !important;color:#402661!important;" v-html="post.content"></div>
          </div>
          <div class="ps-4 pe-4 pt-3">
            <hr class="purple m-0" style="opacity: 0.05;">
          </div>
          <div class="row d-flex align-items-center ps-4 pe-4 pt-3">
            <div class="col-6">
              <a :href="'/blog/' + post.slug" class="purple font-size-base fw-bold text-decoration-none">Detay</a>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-end">
              <a :href="'/blog/' + post.slug">
                <svg style="width:18px;fill:#402661;cursor:pointer;"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useBlogStore } from '../../store/blog'
import { useRoute } from 'vue-router'
// import BlogCard from '../../components/bonapply/BlogCard.vue'
import { useAnalyticsStore } from '@/store/analytics'
export default defineComponent({
  name: 'BlogView',
  data () {
    return {}
  },
  setup () {
    onMounted(() => {
      blogStore.post = null
      document.title = 'VakıfBank | Blog'
      // refModal.value.show()
      blogStore.resetPaging()
      const listElm = document.querySelector('html')
      window.addEventListener('scroll', e => {
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          if (keepScrolling.value) {
            keepScrolling.value = false
            setTimeout(function () {
              keepScrolling.value = true
            }, 500)
            getBlogList()
          }
        }
      })
      getBlogList()
    })
    const getBlogList = () => {
      if (blogStore.getData) {
        blogStore.getPosts().then((response) => {
          if (route.params.slug === undefined) {
            blogStore.post = blogStore.posts[0]
          } else {
            blogStore.getSingle(route.params.slug).then((response) => {
              analyticsStore.sendView('blog', blogStore.post.id)
            })
          }
        })
      }
    }
    const analyticsStore = useAnalyticsStore()
    const keepScrolling = ref(true)
    const onNewButtonClick2 = () => {
      refModal.value.show()
    }
    const onNewButtonClick3 = () => {
      refModal.value.show()
    }
    const refModal = ref(null)
    const route = useRoute()
    const blogStore = useBlogStore()
    // blogStore.getPopularPosts().then((response) => {})

    // blogStore.getLatestPosts().then((response) => {})

    return {
      blogStore,
      refModal,
      onNewButtonClick2,
      onNewButtonClick3,
      analyticsStore
    }
  },
  components: {
    // BlogCard
  }
})
</script>
<style lang="scss" scoped>
@import 'src/styles/media_query.scss';
@import 'src/styles/bs5_theme.scss';
@import 'src/styles/vakifbank-style.scss';
.hover-border{
  border: solid 5px transparent !important;
}
.hover-border:hover{
  border: solid 5px #fdb913 !important;
}
</style>
