import { BaseService } from './BaseService'
const SEARCH = '/v1/search/'

class SearchService extends BaseService {
  search (value) {
    // const pageKey = (undefined !== useAuthStore().getToken() && useAuthStore().getToken() !== null) ? 'in_house_user' : 'external_user'
    const pageKey = 'all'
    const data = {
      search: value
    }
    return this.post(SEARCH + pageKey + '/full', data)
  }

  tagSearch (value) {
    // const pageKey = (undefined !== useAuthStore().getToken() && useAuthStore().getToken() !== null) ? 'in_house_user' : 'external_user'
    const pageKey = 'all'
    const data = {
      search: value
    }
    return this.post(SEARCH + pageKey + '/tag', data)
  }

  userSearch (value) {
    // const pageKey = (undefined !== useAuthStore().getToken() && useAuthStore().getToken() !== null) ? 'in_house_user' : 'external_user'
    const pageKey = 'all'
    const data = {
      search: value
    }
    return this.post(SEARCH + pageKey + '/user', data)
  }
}

export default new SearchService()
