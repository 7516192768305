<template>
  <layout-type-1>
    <div class="mb-4">
      <FormTextSlot form-text="Şifremi Unuttum"></FormTextSlot>
    </div>
    <Form></Form>
    <form @submit="onSubmit">
      <div class="form-outline mb-4 text-start">
        <label for="email" class="mb-1">E-Posta</label>
        <field id="email" name="email" placeholder="E-Posta Adresinizi Yazınız" class="form-control shadow-none" />
        <span class="red">{{ errors.email }}</span>
      </div>
      <div class="col-12">
        <strong class="text-danger"><i>VakıfBank kullanıcıları şifrelerini sistemden güncelleyebilir</i></strong>
      </div>
      <div class="d-grid pt-1 mb-4 text-start col-4 offset-4">
        <ButtonSlot button-text="Gönder"></ButtonSlot>
      </div>
    </form>
  </layout-type-1>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { useForm, Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { useAuthStore } from '../../store/auth'
import { useRouter } from 'vue-router'
import LayoutType1 from '../../layouts/LayoutType1.vue'
import ButtonSlot from '../../layouts/ButtonSlot.vue'
import FormTextSlot from '../../layouts/FormTextSlot'

export default defineComponent({
  name: 'ForgotPasswordView',
  data () { return {} },
  setup (props) {
    onMounted(() => {
      document.title = 'VakıfBank | Şifremi Unuttum'
    })
    const router = useRouter()
    const authStore = useAuthStore()
    const schema = yup.object().shape({
      email: yup.string().required('*Lütfen E-Posta Adresinizi Yazınız').email()
    })

    const { handleSubmit, isSubmitting, errors } = useForm({
      validationSchema: schema
    })

    const onSubmit = handleSubmit((values, actions) => {
      authStore.passwordUpdateabilityCheck(values, actions).then(
        response => {
          if (response.status === 200 && response.data.data.status === 1) {
            authStore.resetPassword(values, actions).then(
              response => {
                if (response.status === 200) {
                  router.push('/login')
                }
              }
            )
          } else {
            // toast.error(response.data.message)
          }
        }
      )
    })

    return {
      authStore,
      onSubmit,
      isSubmitting,
      errors
    }
  },
  components: {
    Form,
    Field,
    LayoutType1,
    ButtonSlot,
    FormTextSlot
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>

@import 'src/styles/bs5_theme.scss';
</style>
