<template>
    <div class="row mb-5 notification-list">
        <div class="col-12 mb-3">
            <p class="m-0 font-size-base black fw-bold">Bildirimler</p>
        </div>

        <template v-for="notification in notificationStore.headerNotifications" :key="notification">
        <div class="step completed d-flex justify-content-between">
            <div class="v-stepper">
            <div class="circle  d-flex align-items-center justify-content-center">
                <i class="fa-solid fa-circle-check font-size-xxs black"></i>
            </div>
            <div class="line"></div>
            </div>
            <div class="content" style="width:100%">
                <p class="font-size-sm black notification-message" v-html="notification.message"></p>
                <p class="light-gray font-size-sm d-flex justify-content-between"><span>{{ notification.date.date }}</span> <span>{{ notification.date.time }}</span></p>
            </div>
        </div>
        </template>
    </div>

    <div class="row d-none">
            <div class="col-12 mb-3">
                <p class="m-0 font-size-base dark-gray fw-bold">Geçmiş</p>
            </div>

            <div class="step passive d-flex justify-content-between">
            <div class="v-stepper">
            <div class="circle  d-flex align-items-center justify-content-center">
            <i class="fa-solid fa-xmark font-size-xxs black"></i>
            </div>
            <div class="line"></div>
            </div>
            <div class="content col-6">
            <p class="font-size-sm light-gray">Ufuk Öztürk <strong>Fikriniz yayınlandı!</strong></p>
            </div>
            <div class="content col-6 d-flex align-items-center justify-content-end pe-2">
            <p class="me-4 light-gray font-size-sm">19.11.2022</p>
            <p class="light-gray font-size-sm">10:12</p>
            </div>
            </div>

    </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { useNotificationStore } from '../../store/notification'

export default defineComponent({
  name: 'CommonView',
  data () { return {} },
  setup () {
    const notificationStore = useNotificationStore()

    onMounted(() => {
      notificationStore.getHeaderNotifications()
    })

    return {
      notificationStore
    }
  },
  components: {

  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>
@import 'src/styles/media_query.scss';
@import 'src/styles/bs5_theme.scss';
</style>
