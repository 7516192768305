<template>
    <div class="container">
        <div class="row mb-3">
            <div class="col-12">
              <div class="row w-100 mb-2">
                <div class="col-9 d-flex align-items-center">
                  <h5 class="m-0">{{ programStore.active_event_title }}</h5>
                </div>
              </div>
                <div v-if="isLoading">
                  Yükleniyor...
                </div>
                <div v-if="feedStore.post === null && !isLoading">
                </div>
                <div v-if="feedStore.post != null">
                  <wall-post :data="feedStore.post" layout="singlePost"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useFeedStore } from '../../store/feed'
import { useProgramStore } from '../../store/program'
import WallPost from '../../components/bonapply/WallPost'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'FeedDetail',
  data () { return {} },
  setup () {
    onMounted(() => {
      feedStore.resetPost()
      document.title = 'VakıfBank | Feed'
      getFeedDetail()
    })
    const getFeedDetail = () => {
      isLoading.value = true
      feedStore.singleFeed(true).then(
        response => {
          programStore.setActiveEventTitle(response.data.data.event.title)
          programStore.setActiveEventCoverSubject(response.data.data.event.cover_subject)
          programStore.setActiveEventSubject(response.data.data.event.subject)
          document.title = 'VakıfBank | ' + response.data.data.post.answers.feed_program_apply_title.formItemReply
          isLoading.value = false
        }
      )
    }
    const feedStore = useFeedStore()
    const programStore = useProgramStore()
    const route = useRoute()
    const isLoading = ref(false)
    return {
      feedStore,
      programStore,
      isLoading,
      route
    }
  },
  components: {
    WallPost
  },
  methods: {

  }
})
</script>
