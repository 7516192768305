<template>
    <div class="container mb-5 down_md_pb_none" style="padding-bottom:3rem;" >
        <div class="row" v-for="sectionItem in section.items" :key="sectionItem">
            <div class="col-xl-6 col-lg-6 col-12 down_992_margin_bottom">
                <div v-if="section.title" class="bg_color w-25 py-2 text-center font-size-base yellow rounded-pill fw-bold mb-3">
                    {{section.title}}
                </div>
                <div>
                    <p class="m-0 text-left font-size-3xl purple fw-bold line-height down_md2_font3">
                      <a :href="sectionItem.link" class="purple text-decoration-none">{{sectionItem.title}}</a>
                    </p>
                </div>
                <div>
                    <p class="m-0 text-left font-size-base purple" v-html="sectionItem.content"></p>
                </div>
            </div>
             <div class="col-xl-6 col-lg-6 col-12 d-flex justify-content-end">
               <a :href="sectionItem.link">
                   <img :src="sectionItem.coverImg" alt="vakıfbank blog image" class="img-fluid rounded-10 h-100" style="width:100vh!important;">
               </a>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    section: {}
  },
  setup (props) {}
})
</script>
<style lang="scss" scoped>
@import 'src/styles/media_query.scss';
@import 'src/styles/bs5_theme.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500;700&display=swap');
*{
    font-family: 'Roboto', sans-serif !important;
}
</style>
