<template>
     <div class="container mt-3">
        <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand me-5" href="#" alt="v-lab logo">
                    <img src="@/assets/LeadImages/landing-img9.svg" alt="landing image" class="img-fluid" width="140"/>
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item me-4">
                    <a class="nav-link purple fw-light" href="#aboutUs">Platform Hakkında</a>
                    </li>
                    <li class="nav-item me-4">
                    <a class="nav-link purple fw-light" href="/programs">Programlar</a>
                    </li>
                    <li class="nav-item me-4">
                    <a class="nav-link purple fw-light" href="/Blog-View">Blog</a>
                    </li>
                   <li class="nav-item me-4" v-if="!authStore.isLoggedIn">
                    <a class="nav-link purple fw-light" href="/contact-logout-view">İletişim</a>
                   </li>
                   <li class="nav-item me-4" v-if="authStore.isLoggedIn">
                    <a class="nav-link purple fw-light" href="/contact-view">İletişim</a>
                   </li>
                </ul>
                <form class="d-flex" role="search">
                    <a v-if="!authStore.isLoggedIn" href="/login" class="btn rounded-10 login_button fw-bold pe-5 ps-5 pt-3 pb-3" style="text-shadow:none!important;" type="submit">Giriş Yap</a>
                </form>

                <div v-if="authStore.isLoggedIn" class="dropdown menu_dropdown  down_992_display_none d-flex">
                  <button class="btn dropdown-toggle d-flex pe-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    {{ authStore.user }}
                    <i class="fa-solid fa-user-large ms-2"></i>
                  </button>
                  <ul class="dropdown-menu w-100 border-0 bg-white rounded-20 shadow" aria-labelledby="dropdownMenuButton1">
                    <li>
                      <a href="/my-ideas" class="dropdown-item px-4" >Fikirlerim</a>
                    </li>
                    <hr class="m-1 ms-4 me-4">
                    <li>
                      <a href="/my-profile" class="dropdown-item px-4" >Profilim</a>
                    </li>
                    <hr class="m-1 ms-4 me-4">
                    <li>
                      <a href="/logout" class="dropdown-item px-4" type="submit">Çıkış Yap</a>
                    </li>
                  </ul>
                </div>
              </div>
        </nav>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import { useAuthStore } from '../../store/auth'

export default defineComponent({
  setup () {
    const authStore = useAuthStore()

    return {
      authStore
    }
  }
})
</script>
<style lang="scss" scoped>
@import 'src/styles/media_query.scss';
@import 'src/styles/bs5_theme.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500;700&display=swap');
*{
    font-family: 'Roboto', sans-serif !important;
}
a:hover{
    font-weight: bold !important;
    text-shadow: 0.1px 0.1px #402661;
}
.dropdown-item:hover{
  border-radius:10px !important;
}
</style>
