<template>
  <div class="container">
      <div class="row">
         <div class="col-md-8 offset-md-2 text-center px-3">
          <h3 class="bg-light text-primary py-2">{{ applyStore.eventForm.title }}</h3>
        </div>
        <div class="col-md-8 offset-md-2 text-start px-5">
          <figure>
          <blockquote class="blockquote">
          <p>Başvuru sayfasında "Gönder"e basmadığınız sürece, cevaplarınız sistemde kayıtlı kalacak. Son başvuru tarihine kadar istediğiniz zaman tekrar bu forma girerek başvurunuzu düzenleyebilir ardından "Gönder" tuşu ile başvurunuzu tamamlayabilirsiniz.</p>
          </blockquote>
          <figcaption class="blockquote-footer mt-2">
          Başvuru formundaki bilgilerin detaylı ve net olması önemlidir. <cite title="Source Title">Teşekkürler!</cite>
          </figcaption>
          </figure>
          <p></p>
          <p></p>
        </div>
        <div class="col-md-8 offset-md-2 text-start p-4">
            <apply-form :applyFormUId="applyFormUId" @onApplySuccess="onApplySuccess"/>
        </div>
      </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useApplyStore } from '../../store/apply'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'ApplyFormView',
  data () { return {} },
  setup () {
    onMounted(() => {
      document.title = 'VakıfBank | Başvuru'
      if (applyUId === undefined) {
        applyStore.create(eventFormToken).then(
          response => {
            if (response.status === 200) {
              applyFormUId.value = response.data.data.apply.uid
            } else {
              window.history.back()
            }
          }
        )
      } else {
        applyFormUId.value = applyUId
      }
    })
    const route = useRoute()
    const router = useRouter()
    const eventFormToken = route.params.token
    const applyUId = route.params.applyUId
    const applyFormUId = ref(null)
    const applyStore = useApplyStore()
    const toast = useToast()
    const onApplySuccess = () => {
      toast.success('Tebrikler, başvurunuz tamamlandı.')
      router.push('/programs')
    }

    return {
      applyStore,
      applyFormUId,
      onApplySuccess
    }
  },
  components: {

  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>
@import 'src/styles/media_query.scss';
@import 'src/styles/bs5_theme.scss';
</style>
