<template>
  <div class="container">
    <div class="row mb-5">
      <div class="col-12">
        <div class="d-flex align-items-start down_md2_block">
          <div class="d-block w-35 down_992_width_full me-2 d-none">
             <nav>
              <div class="nav nav-tabs border-0" id="nav-tab" role="tablist">
                <button @click="blogStore.getLatestPosts()" class="nav-link active font-size-lg p-2 pt-0 text-uppercase black fw-bold ps-0" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true" v-if="blogStore.posts">Tümü <span class="fw-light">( {{ blogStore.posts.length }} )</span></button>
                <button @click="blogStore.getPopularPosts()" class="nav-link font-size-lg p-2 pt-0 text-uppercase black fw-bold" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false"  v-if="blogStore.popular_posts">En Popüler</button>
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                <div
                        class="nav flex-column nav-pills me-2 d-block down_md_w100"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                      <BlogSearch/>
                      </div>
              </div>
              <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                <div
                        class="nav flex-column nav-pills me-2 d-block down_md_w100"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                      <PopularBlogSearch/>
                      </div>
              </div>
            </div>
          </div>
          <div class="tab-content w-100 mt-5" id="v-pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="v-pills-home"
              role="tabpanel"
              aria-labelledby="v-pills-home-tab"
            >
              <div class="card mb-3 p-5 down_sm_padding_1" v-if="blogStore.post">
                <div class="row">
                  <div class="col-xl-4 col-12 pb-3" v-if="blogStore.post.coverImg != null && blogStore.post.coverImg != '' && blogStore.post.videoPath == ''">
                    <img
                      :src="blogStore.post.coverImg"
                      alt="blog image"
                      class="img-fluid rounded-10"
                    />
                  </div>
                  <div class="col-xl-4 col-12 pb-3" v-if="blogStore.post.videoPath != null && blogStore.post.videoPath != ''">
                    <video width="400" height="230" controls style="max-width: 100%" class="rounded-10">
                      <source :src="blogStore.post.videoPath" type="video/mp4">
                    </video>
                  </div>
                  <div class="col-xl-8 col-12">
                    <div class="card-body p-0">
                      <h4 class="card-title black fw-bold font-size-2xl">
                        {{ blogStore.post.title }}
                      </h4>
                      <p
                        class="card-text black font-size-lg"
                        v-html="blogStore.post.content"
                      ></p>
                    </div>
                  </div>
                  <div class="col-12 mt-5">
                    <p class="card-text black font-size-lg"></p>
                  </div>
                  <div class="col-12 mt-3">
                    <p class="card-text black font-size-lg">
                      {{ blogStore.post.releaseDate }}
                    </p>
                  </div>
                  <!--
                  <div class="col-12 d-flex">
                    <p class="card-text black font-size-lg me-2">YAZAN:</p>
                    <p class="card-text black font-size-lg">
                      {{ blogStore.post.author.name }} {{ blogStore.post.author.surname }}
                    </p>
                  </div>
                  -->
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-profile"
              role="tabpanel"
              aria-labelledby="v-pills-profile-tab"
            >
              ..2.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-5 d-none">
      <div class="col-12 mb-4 up_md2_ps_0">
        <p class="m-0 black text-uppercase fw-bold font-size-2xl">
          SON EKLENENLER
        </p>
      </div>
      <div class="col-xl-4 col-lg-4 col-12 border p-5 down_sm_padding_1 hover_bg_primary rounded-10" v-for="post in blogStore.posts" :key="post">
        <BlogCard :post="post"></BlogCard>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useBlogStore } from '../../store/blog'
import { useRoute } from 'vue-router'
import BlogCard from '../../components/bonapply/BlogCard.vue'
import BlogSearch from '@/components/bonapply/BlogSearch'
import PopularBlogSearch from '@/components/bonapply/PopularBlogSearch'
import { useAnalyticsStore } from '@/store/analytics'
export default defineComponent({
  name: 'BlogDetailView',
  data () {
    return {}
  },
  setup () {
    onMounted(() => {
      blogStore.post = null
      document.title = 'VakıfBank | Blog'
      // refModal.value.show()
    })
    const analyticsStore = useAnalyticsStore()
    const onNewButtonClick2 = () => {
      refModal.value.show()
    }
    const onNewButtonClick3 = () => {
      refModal.value.show()
    }
    const refModal = ref(null)
    const route = useRoute()
    const blogStore = useBlogStore()
    blogStore.getPosts().then((response) => {
      if (route.params.slug === undefined) {
        blogStore.post = blogStore.posts[0]
      } else {
        blogStore.getSingle(route.params.slug).then((response) => {
          analyticsStore.sendView('blog', blogStore.post.id)
        })
      }
    })
    blogStore.getPopularPosts().then((response) => {})

    blogStore.getLatestPosts().then((response) => {})

    return {
      blogStore,
      refModal,
      onNewButtonClick2,
      onNewButtonClick3,
      analyticsStore
    }
  },
  components: {
    BlogCard,
    BlogSearch,
    PopularBlogSearch
  }
})
</script>
<style lang="scss" scoped>
@import "src/styles/media_query.scss";
</style>
