<template>
<div class="container">
   <div class="row my-5">
        <div class="col-12 text-start mb-3">
            <p class="m-0 text-black-color font-size-xxxl fw-500">S.S.S</p>
        </div>
        <div class="col-12">
           <div class="accordion" id="accordionExample">
            <div class="accordion-item mb-2" :key="sss" v-for="sss in faqStore.faqInfo">
                <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button position-relative d-flex align-items-center font-size-base pb-2 collapsed" style="padding-left:1.6rem;" type="button" data-bs-toggle="collapse" :data-bs-target="'#completed'+sss.id" aria-expanded="false" :aria-controls="'completed'+sss.id" data-v-1399160e="">{{sss.question}}</button>
                </h2>
                <div :id="'completed'+sss.id" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body text-start font-size-base ps-5 pt-2">
                   {{sss.answer}}
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import { useFaqStore } from '../../store/faq'

export default defineComponent({
  setup () {
    // Sık Sorulan Sorular Bilgilerini Almak
    const faqStore = useFaqStore()
    faqStore.getFaqInfo()
    // End
    const route = useRoute()
    return {
      route,
      faqStore
    }
  },
  components: { }
})
</script>
<style lang="scss" scoped>
@import 'src/styles/bs5_theme.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500;700&display=swap');
*{
    font-family: 'Roboto', sans-serif !important;
}
.accordion-item{
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom: 0 !important;
}
.accordion-button{
    border-bottom: solid 1px #e8e8e8;
}
.accordion-collapse.collapse{
    border-top: 1px solid #ffb300;
}
button:focus:not(:focus-visible){
    box-shadow: none !important;
}
.accordion-button:not(.collapsed){
    box-shadow: none !important;
    background-color: transparent !important;
    color: #203145 !important;
}
.accordion-item[data-v-1399160e]:first-of-type{
    border-bottom: 0 !important;
}
button[data-v-1399160e]:focus:not(:focus-visible){
    border-bottom: solid 1px #e8e8e8 !important;
    border-radius: 0 !important;
}
.accordion-button::after {
    position: absolute !important;
    left: 0 !important;
    margin-right: 1rem !important;
    zoom: .4 !important;
        }
.accordion-button:not(.collapsed)::after {
    position: absolute !important;
    left: 0 !important;
    margin-right: 1rem !important;
    zoom: .4 !important;
}
</style>
