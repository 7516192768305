<template>
<div class="comment-indent-item">
    <template v-if="!isDeleted">
      <div>
          <p class="m-0 black font-size-base">{{ comment.user.name + " " + comment.user.surname  }} </p>
          <p class="m-0 black font-size-base"> {{ message }} </p>
      </div>
      <div v-if="actionsEnabled" class="row mt-3 mb-3">
          <div class="col-lg-6">
              <div class="d-flex align-items-center">
                  <div v-if="canILike()">
                    <p v-if="!isLikedByMe" @click="onLikeClick()" class="m-0 me-2 black font-size-lg fw-bold pointer">Beğen</p>
                    <p v-if="isLikedByMe" @click="onUndoLikeClick()" class="m-0 me-2 black font-size-lg fw-bold pointer">Beğeniyi Geri Al</p>
                  </div>
                  <div>
                    <span class="me-2 black">│</span>
                  </div>
                  <div>
                      <p class="m-0 me-2 black font-size-lg fw-bold pointer" @click="onWriteCommentClick" >Yorum Yap</p>
                  </div>

                  <template v-if="canIDelete()">
                    <div>
                      <span class="me-2 black">│</span>
                    </div>
                    <div>
                      <p v-if="!isDeleted" @click="onDeleteClick()" class="m-0 me-2 black font-size-lg fw-bold pointer">Yorumu Sil</p>
                    </div>
                  </template>
              </div>
          </div>
          <div class="col-lg-6">
              <div class="d-flex align-items-center justify-content-end">
                  <div class="d-flex align-items-center me-3">
                      <i class="fa-solid fa-thumbs-up me-2 dark-gray"></i>
                      <p class="m-0 dark-gray font-size-base">{{ likeCount }}</p>
                  </div>
                  <div class="d-flex align-items-center me-3">
                      <i class="bi bi-chat-left me-2 dark-gray"></i>
                      <p class="m-0 dark-gray font-size-base">{{ comment.counts.comments }}</p>
                  </div>
              </div>
          </div>
      </div>
      <div v-if="showInput" class="col-12 mb-3">
          <comment-input parentEntity="Comment" :parentEntityId="comment.id" :parentId="comment.id" :show="true" @onWriteCommentComplete="onWriteCommentComplete"/>
      </div>
    </template>
    <template v-if="isDeleted">
      <div>
        <p class="m-0 black font-size-base"> {{ message }} </p>
      </div>
    </template>
</div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useAuthStore } from '@/store/auth'
import { useLikeStore } from '@/store/like'
import { useCommentStore } from '@/store/comment'
import CommentInput from '../../components/bonapply/CommentInput'

export default defineComponent({
  name: 'CommentItem',
  props: {
    isRoot: null,
    comment: null
  },
  data () { return {} },
  setup (props, context) {
    const authStore = useAuthStore()
    const likeStore = useLikeStore()
    const commentStore = useCommentStore()
    const showInput = ref(false)
    const likeCount = ref(props.comment.counts.likes)
    const isLikedByMe = ref(props.comment.isLikedByMe)
    const isDeleted = ref(props.comment.isDeleted)
    const message = ref(props.comment.message)
    const actionsEnabled = ref(true)
    const onWriteCommentClick = function () {
      showInput.value = true
    }
    const onWriteCommentComplete = function () {
      showInput.value = false
    }

    const onLikeClick = () => {
      likeStore.like('Comment', props.comment.id).then(
        response => {
          if (response.status === 200) {
            isLikedByMe.value = true
            likeCount.value++
          }
        }
      )
    }

    const onUndoLikeClick = () => {
      likeStore.undoLike('Comment', props.comment.id).then(
        response => {
          if (response.status === 200) {
            isLikedByMe.value = false
            likeCount.value--
          }
        }
      )
    }

    const canILike = () => {
      return true
    }

    const canIDelete = () => {
      return isThisMyComment()
    }

    const isThisMyComment = () => {
      return (props.comment.user.username === authStore.user || props.comment.user.email === authStore.user)
    }

    const onDeleteClick = () => {
      actionsEnabled.value = false
      message.value = 'Yorumunuz siliniyor...'
      commentStore.remove(props.comment.id).then(
        response => {
          if (response.status === 200) {
            const data = {
              parentEntity: commentStore.activeRootEntity,
              parentEntityId: commentStore.activeRootEntityId
            }
            commentStore.get(data).then(response => {
              message.value = 'Yorum silindi!'
            })
          }
        }
      )
    }

    return {
      showInput,
      onWriteCommentClick,
      onWriteCommentComplete,
      onLikeClick,
      onUndoLikeClick,
      likeCount,
      canILike,
      isLikedByMe,
      canIDelete,
      isDeleted,
      onDeleteClick,
      message,
      actionsEnabled
    }
  },
  components: {
    CommentInput
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>
@import 'src/styles/bs5_theme.scss';
</style>
