<template>
  <layout-type-1>
    <div class="mb-4">
      <FormTextSlot form-text="Şifre Yenileme"></FormTextSlot>
    </div>
    <Form></Form>
    <form @submit="onSubmit">
        <div class="form-outline mb-4 text-start">
            <field name="new_password" placeholder="Parola" class="form-control shadow-none" type="password" />
            <span class="red">{{ errors.new_password }}</span>
            <span class="red" v-if="errorMessageField === 'password'">{{ errorMessage }}</span>
        </div>

        <div class="form-outline mb-4 text-start">
            <field name="new_password_again" placeholder="Parola (Tekrar)" class="form-control shadow-none" type="password" />
            <span class="red">{{ errors.new_password_again }}</span>
            <span class="red" v-if="errorMessageField === 'password'">{{ errorMessage }}</span>
        </div>
      <div class="d-grid pt-1 mb-4 text-start col-4 offset-4">
          <ButtonSlot button-text="Gönder"></ButtonSlot>
        </div>
    </form>
  </layout-type-1>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useForm, Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { useAuthStore } from '../../store/auth'
import { useRouter, useRoute } from 'vue-router'
import LayoutType1 from '../../layouts/LayoutType1.vue'
import ButtonSlot from '../../layouts/ButtonSlot.vue'
import FormTextSlot from '../../layouts/FormTextSlot'

export default defineComponent({
  name: 'RenewPasswordView',
  data () { return {} },
  setup (props) {
    onMounted(() => {
      document.title = 'VakıfBank | Şifre Yenileme'
    })
    const route = useRoute()
    const router = useRouter()
    const authStore = useAuthStore()
    const errorMessage = ref(null)
    const errorMessageField = ref(null)
    const resetErrorMessage = () => {
      errorMessage.value = ''
      errorMessageField.value = ''
    }
    const schema = yup.object().shape({
      new_password: yup.string().required('*Parolanızı Yazınız'),
      new_password_again: yup.string().required('*Parolanızı Tekrar Yazınız').oneOf([yup.ref('new_password'), null], '*Parolalar Birbirleriyle Eşleşmiyor')
    })

    const { handleSubmit, isSubmitting, errors } = useForm({
      validationSchema: schema
    })

    const onSubmit = handleSubmit((values, actions) => {
      resetErrorMessage()
      values.reset_token = route.params.token
      delete values.new_password_again
      authStore.renewPassword(values).then(
        response => {
          if (response.status === 200) {
            router.push('/login')
          } else {
            if (response.response.data.messageType === 'formInput') {
              errorMessage.value = response.response.data.message
              errorMessageField.value = response.response.data.messageField
            }
          }
        }
      )
    })

    return {
      authStore,
      onSubmit,
      isSubmitting,
      errors,
      errorMessage,
      errorMessageField
    }
  },
  components: {
    Form,
    Field,
    LayoutType1,
    ButtonSlot,
    FormTextSlot
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>
@import 'src/styles/bs5_theme.scss';
</style>
