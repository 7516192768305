import { acceptHMRUpdate, defineStore } from 'pinia'
import searchService from '@/services/search.service'

export const useSearchStore = defineStore({
  id: 'searchStore',
  state: () => ({
    searchTerm: '',
    results: [],
    tagResults: [],
    userResults: []
  }),
  setup () {},
  actions: {
    search (value) {
      return searchService.search(value).then(
        response => {
          this.results = response.data.data.result
          return response
        }
      )
    },
    tagSearch (value) {
      return searchService.tagSearch(value).then(
        response => {
          this.tagResults = response.data.data.result
          return response
        }
      )
    },
    userSearch (value) {
      return searchService.userSearch(value).then(
        response => {
          this.userResults = response.data.data.result
          return response
        }
      )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSearchStore, import.meta.hot))
}
