<template>
    <div class="mb-5">
        <div class="row mb-4">
            <div class="col-xl-8 col-lg-9 col-12">
                <div class="mb-3">
                    <p class="m-0 black font-size-xxl fw-bold">Fikrine {{ data.requestUser.name + " " + data.requestUser.surname }} Ortak Olma Talebinde Bulundu</p>
                </div>
                <div class="mb-3">
                    <p class="m-0 black font-size-xl">{{data.feed.answers.feed_program_apply_title.formItemReply}}</p>
                </div>
                 <div class="d-flex align-items-center">
                    <div class="me-3">
                        <p class="m-0 black font-size-lg text-capitalize"> <i class="fa-solid fa-user me-2" style="width:14px;"></i>{{data.feed.apply.user.name + " " + data.feed.apply.user.surname}}</p>
                    </div>
                </div>
             </div>
        </div>
        <div class="row">
            <div class="col-xl-8 col-lg-9 col-12 d-flex align-items-center">
                <button v-if="status === 0" @click="reply('approve')" type="button" style="height:37px!important;" class="btn btn-black text-capitalize me-3 button_4 font-size-base">Kabul et</button>
                <button v-if="status === 0" @click="reply('reject')" type="button" style="height:37px!important;" class="btn btn-light-gray text-capitalize me-3 button_5 font-size-base">Reddet</button>
                <button v-if="status === 1" class="text-capitalize border-0 me-3 button_1 d-flex align-items-center justify-content-center font-size-base" style="cursor: default !important;height:37px !important;">Kabul edildi</button>
                <button v-if="status === 1" @click="reply('leave')" type="button" class="btn btn-black text-capitalize  border-0 me-3 button_4 font-size-base" style="height:37px !important;">Ortaklıktan Çıkar</button>
                <button v-if="status === -1" class="text-capitalize border-0 me-3 button_1 font-size-base d-flex align-items-center justify-content-center" style="cursor: default !important;height:37px!important;">Reddedildi</button>
            </div>
        </div>
        </div>
        <hr class="my-5">
</template>
<script>
import { defineComponent, ref } from 'vue'
import { useCollaborateStore } from '../../store/collaborate'

export default defineComponent({
  name: 'CommonDemandsCard',
  props: {
    data: {}
  },
  data () { return {} },
  setup (props) {
    const collaborateStore = useCollaborateStore()

    const reply = (process) => {
      collaborateStore.reply(props.data.id, process).then(
        response => {
          if (response.status === 200) {
            status.value = response.data.data.partner.status
          }
        }
      )
    }
    const status = ref(props.data.status)
    return {
      reply,
      status
    }
  },
  components: {
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>
@import 'src/styles/media_query.scss';
@import 'src/styles/vakifbank-style.scss';
@import 'src/styles/bs5_theme.scss';
</style>
