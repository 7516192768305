<template>
<landing-header></landing-header>
<div  v-if="landingStore.landingInfo.lenght != 0">
  <div class="" v-for="section in landingStore.landingInfo.data.content.sections" :key="section">
    <div v-if="section.sectionId == 'slider'">
      <slider-section :section="section"></slider-section>
    </div>
    <div v-if="section.sectionId == 'aboutUs'">
        <about-us :section="section" :landingInfo="landingStore.landingInfo"></about-us>
    </div>
    <div v-if="section.sectionId == 'programs'">
       <programs-cart :section="section"></programs-cart>
    </div>
   <div v-if="section.sectionId == 'featuredPrograms'">
    <highlights-cart :section="section"></highlights-cart>
   </div>
    <div v-if="section.sectionId == 'blog'">
      <landing-blog :section="section"></landing-blog>
    </div>
  </div>
</div>
<landing-footer></landing-footer>
</template>
<script>
import { defineComponent } from 'vue'
import LandingHeader from '../../components/landing/LandingHeader.vue'
import LandingBlog from '../../components/landing/LandingBlog.vue'
import ProgramsCart from '../../components/landing/ProgramsCart.vue'
import HighlightsCart from '../../components/landing/HighlightsCart.vue'
import LandingFooter from '../../components/landing/LandingFooter.vue'
import SliderSection from '../../components/landing/SliderSection.vue'
import AboutUs from '../../components/landing/AboutUs.vue'
import { useLandingStore } from '../../store/landing'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/store/auth'

export default defineComponent({
  setup () {
    const route = useRoute()
    const landingStore = useLandingStore()
    if (undefined !== useAuthStore().getToken() && useAuthStore().getToken() !== null) {
      landingStore.getLandingPageInformation('in_house_user_landing_page')
    } else {
      landingStore.getLandingPageInformation('external_user_landing_page')
    }

    return {
      landingStore,
      route
    }
  },
  components: {
    LandingHeader,
    SliderSection,
    AboutUs,
    LandingBlog,
    ProgramsCart,
    HighlightsCart,
    LandingFooter
  }
})
</script>
<style lang="scss" scoped>
@import 'src/styles/bs5_theme.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500;700&display=swap');
*{
    font-family: 'Roboto', sans-serif !important;
}
</style>
