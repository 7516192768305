<template>
    <div class="container">
        <div class="row mb-3">
            <div class="col-12 col-md-3">
              <left-list></left-list>
            </div>
            <div class="col-12 col-md-9">
                <div v-for="apply in applyStore.myApplies" :key="apply">
                  <apply-cart :apply="apply"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useApplyStore } from '../../store/apply'
import ApplyCart from '../../components/bonapply/ApplyCart'
import LeftList from '../../components/bonapply/LeftList.vue'

export default defineComponent({
  name: 'MyAppliesView',
  data () { return {} },
  setup () {
    onMounted(() => {
      applyStore.resetPaging()
      document.title = 'VakıfBank | Başvurularım'
      const listElm = document.querySelector('html')
      window.addEventListener('scroll', e => {
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          if (keepScrolling.value) {
            keepScrolling.value = false
            setTimeout(function () {
              keepScrolling.value = true
            }, 500)
            getMyIdeaList()
          }
        }
      })
      getMyIdeaList()
    })
    const getMyIdeaList = () => {
      if (applyStore.getData) {
        applyStore.getMyApplies()
      }
    }
    const applyStore = useApplyStore()
    const keepScrolling = ref(true)
    return {
      applyStore
    }
  },
  components: {
    ApplyCart,
    LeftList
  },
  methods: {

  }
})
</script>
