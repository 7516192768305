import { BaseService } from './BaseService'
const LANDING_PAGE_PATH = '/v1/static-page'
/* eslint-disable */
class LandingService extends BaseService {
  getLandingPageInformation (key) {
    return this.get(LANDING_PAGE_PATH + '/' +key).then(response => {
      return response
    })
  }
}
export default new LandingService()
/* eslint-disable */
