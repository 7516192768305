<template>
    <div class="container">
        <div class="row mb-3">
            <div class="col-12">
              <div class="row w-100 mb-2">
              </div>
                <div v-if="isLoading">
                  Yükleniyor...
                </div>
                <div v-if="applyStore.post === null && !isLoading">
                </div>
                <div v-if="applyStore.apply != null" style="max-width: 800px; margin: auto">
                  <div class="mb-4" v-for="forms_items_group in applyStore.apply.applyFormCache.forms_items_groups" :key="forms_items_group">
                    <div class="mb-4" v-for="formItem in forms_items_group.form_items" :key="formItem">
                      <form-item :data="formItem" :disabled="true"/>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useApplyStore } from '../../store/apply'

export default defineComponent({
  name: 'ApplyDetail',
  data () { return {} },
  setup () {
    onMounted(() => {
      document.title = 'VakıfBank | Apply'
      getApplyDetail()
    })
    const getApplyDetail = () => {
      isLoading.value = true
      applyStore.applyDetail().then(
        isLoading.value = false
      )
    }
    const applyStore = useApplyStore()
    const isLoading = ref(false)
    return {
      applyStore,
      isLoading
    }
  },
  components: {
  },
  methods: {

  }
})
</script>
