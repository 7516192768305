<template>
<div class="container">
    <div class="row">
      <div class="col-12 col-md-3">
        <div class="d-flex align-items-start down_md_block">
          <div class="nav flex-column nav-pills me-3 pb-5 down_sm_mr_none" id="v-pills-tab" role="tablist" aria-orientation="vertical" style="flex-direction: inherit !important;">
            <div v-if="newIdeaButtonStatus">
              <new-idea/>
            </div>
            <router-link :to="{ name: 'wall', params: { eventUId: route.params.eventUId, eventFormToken: route.params.eventFormToken } }" class="nav-link nav-link-2 text-start px-0 font-size-lg w-100">Program</router-link>
            <router-link :to="{ name: 'my-leaderboard-for-event', params : { eventUId:route.params.eventUId, eventFormToken: route.params.eventFormToken } }" class="nav-link nav-link-2 text-start px-0 font-size-lg w-100">Liderlik Tablosu</router-link>
            <div class="nav-link nav-link-2 text-start font-size-lg down_md_pl_0 ps-0" style="font-weight: bold">En Popüler</div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-9">
        <div>
          <div class="row w-100 mb-2">
            <div class="col-9 d-flex align-items-center">
              <h5 class="m-0">{{ programStore.active_event_title }}</h5>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-borderless">
              <thead>
                  <tr>
                  <th scope="col" class="font-size-xl dark-gray fw-bold">
                      <img src="@/assets/bonapply/LeadImages/plane.png" alt="plane image" style="width:50px!important"/>
                  </th>
                  <th scope="col" class="font-size-xl dark-gray fw-bold">Fikirler</th>
                  <th scope="col" class="font-size-lg dark-gray-2 fw-bold text-center">
                      <i class="fa-solid fa-thumbs-up me-2 dark-gray"></i>
                      Beğeni
                  </th>
                  <th scope="col" class="font-size-lg dark-gray-2 fw-bold text-center">
                      <i class="bi bi-chat-left me-2 dark-gray"></i>
                      Yorumlama
                  </th>
                  <th scope="col" class="font-size-lg dark-gray-2 fw-bold text-center">
                      <i class="bi bi-eye-fill me-2 dark-gray"></i>
                      Görüntüleme
                  </th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="border-bottom" v-for="popular in popularStore.popular_list" :key="popular">
                  <popular-card :data="popular"></popular-card>
                  </tr>
              </tbody>
              </table>
          </div>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import { defineComponent, onMounted, ref } from 'vue'
import PopularCard from '../../components/bonapply/PopularCard.vue'
import { usePopularStore } from '@/store/popular'
import { useProgramStore } from '@/store/program'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'LeaderBoardView',
  data () { return {} },
  setup () {
    onMounted(() => {
      document.title = 'VakıfBank | Popülerlik'
    })
    const route = useRoute()
    const popularStore = usePopularStore()
    const programStore = useProgramStore()
    const newIdeaButtonStatus = ref(false)
    popularStore.getPopularInfo().then(
      response => {
        if (response.data.data.event.title !== '') {
          newIdeaButtonStatus.value = !!(response.data.data.event.status)
          programStore.setActiveEventTitle(response.data.data.event.title)
          programStore.setActiveEventCoverSubject(response.data.data.event.cover_subject)
          programStore.setActiveEventSubject(response.data.data.event.subject)
          document.title = 'VakıfBank | ' + response.data.data.event.title + ' Popülerlik'
        }
      }
    )

    return {
      popularStore,
      programStore,
      newIdeaButtonStatus,
      route
    }
  },
  components: {
    PopularCard
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>
@import 'src/styles/media_query.scss';
</style>
