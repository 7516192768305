import { BaseService } from './BaseService'
import { useAuthStore } from '@/store/auth'
const KVKK = '/v1/static-page/kvkk'

class KvkkService extends BaseService {
  getKvkkInfo () {
    const sectionKey = (undefined !== useAuthStore().getToken() && useAuthStore().getToken() !== null) ? 'inHouseUserKvkk' : 'externalUserKvkk'
    return this.get(KVKK + '?sectionKey=' + sectionKey)
  }
}

export default new KvkkService()
