<template>
  <layout-type-1>
    <Form></Form>
    <form @submit="onSubmit" v-if="meStore.me">
      <h5 class="text-start mb-4">Kişisel Bilgiler</h5>
      <div class="pt-1 mb-4">
        <div class="form-outline mb-4 text-start">
          <label for="name" class="mb-1">Ad</label>
          <field name="name" disabled placeholder="Adınızı Yazınız" class="form-control" :value="meStore.me.name"/>
          <span>{{ errors.name }}</span>
        </div>
        <div class="form-outline mb-4 text-start">
          <label for="surname" class="mb-1">Soyad</label>
          <field name="surname" disabled placeholder="Soyadınızı Yazınız" class="form-control" :value="meStore.me.surname"/>
          <span>{{ errors.surname }}</span>
        </div>
         <div class="form-outline mb-4 text-start">
          <label for="mail" class="mb-1">E-Posta</label>
          <field name="mail" placeholder="Mail" style="color: #989bba !important;" class="form-control fw-lighter" disabled :value="meStore.me.email"/>
        </div>
        <div class="form-outline mb-4 text-start d-none">
          <field name="phone" placeholder="Telefon Numaranızı Yazınız" class="form-control" :value="meStore.me.phone"/>
          <span>{{ errors.phone }}</span>
        </div>
        <div class="pt-1 mb-4 text-start d-none">
          <ButtonSlot class="w-100" :disabled="isSubmitting" button-text="Güncelle"></ButtonSlot>
        </div>
        <div class="w-100 text-center d-none">
          <a href="/profile-password-renew">Şifremi Değiştir</a>
        </div>
      </div>
    </form>
  </layout-type-1>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { useForm, Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { useMeStore } from '../../store/me'
import LayoutType1 from '../../layouts/LayoutType1.vue'
import ButtonSlot from '../../layouts/ButtonSlot.vue'

export default defineComponent({
  name: 'MyProfileView',
  data () { return {} },
  setup () {
    onMounted(() => {
      document.title = 'VakıfBank | Profilim'
    })
    const meStore = useMeStore()
    const schema = yup.object().shape({
      name: yup.string().required('*Lütfen Adınızı Yazınız'),
      surname: yup.string().required('*Lütfen Soyadınızı Yazınız')
    })

    const { handleSubmit, isSubmitting, errors } = useForm({
      validationSchema: schema
    })

    const onSubmit = handleSubmit((values, actions) => {
      meStore.updateMyProfileData(values)
    })

    meStore.getMyProfileData()

    return {
      meStore,
      onSubmit,
      isSubmitting,
      errors
    }
  },
  components: {
    Form,
    Field,
    ButtonSlot,
    LayoutType1
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>

@import 'src/styles/bs5_theme.scss';
.card{border-width: $border-width;
}
</style>
