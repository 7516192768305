<template>
  <div class="container" v-if="!isContentLoaded">
    Lütfen bekleyiniz...
  </div>
  <div class="container" v-if="isContentLoaded">
    <div class="row">
      <div class="col-6 d-none">
          <nav class="navbar navbar-light navbar-expand-lg">
            <ul class="navbar-nav">
              <li class="nav-item">
                <router-link :to="{ name: 'programs' }" class="nav-link ps-0" aria-current="page">Programlar</router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'programs', params : { programType:'thematic' }}" class="nav-link">Tematik Çağrılar</router-link>
              </li>
            </ul>
          </nav>
      </div>
      <div class="col-12 d-flex justify-content-end">
          <div class="mb-2">
              <select v-model="statusFilter" class="form-select w-auto border-0" aria-label="Default select example">
                <option value="active">Aktif Programlar</option>
                <option value="complete">Geçmiş Programlar</option>
              </select>
          </div>
      </div>
    </div>

    <div v-if="programStore.base_events">
      <div v-if="programStore.base_events.length===0">
        <h5>Program bulunmuyor.</h5>
      </div>
    </div>

    <div class="row mb-4 programs" v-for="item in programStore.base_events" :key="item">
        <div class="col-12 mb-3 d-none">
          <img :src="item.cover_img" class="img-fluid w-100 img_radius" alt="program_images">
        </div>
        <div class="col-12 text-start d-none">
          <h3>{{ item.title }}</h3>
        </div>
        <div class="col-12 text-start d-none">
          <p class="font_3">
            {{ item.detail }}
          </p>
        </div>
      <div>
        <div class="row mt-4 mb-5">
         <div class="col-xl-4 col-lg-4 col-md-6 col-12 mb-3 d-grid bg-color border-0 rounded-10 div_hover" v-for="item2 in item.events" :key="item2">
             <div class="card border-0 position-relative bg-color rounded-10 hover-border" style="background-color:#f8f8f8!important;">
                 <div class="mb-2 mt-2 px-2 position-relative">
                  <img :src="item2.cover_img" class="img-fluid w-100 rounded-10" alt="program_images">
                </div>
              <div class="card-body">
                  <div class="px-3">
                <div class="text-star" style="height:48px!important;overflow:hidden;">
                  <h5 class="m-0" style="color: #402661 !important;">{{ item2.title }}</h5>
                </div>
                 <div class="font-size-lg dark-gray-2 text-start mb-2" style="color:#793f88 !important;">
                  {{ item2.release_date }} - {{ item2.expire_date }}
                </div>
                <div class="thin_scrollbar text-start" style="height:160px; overflow:auto !important; overflow-x: hidden !important;">
                  <p class="font_3" style="color: #402661 !important;" v-html="item2.cover_subject"></p>
                  <p class="font_3" style="color: #402661 !important;" v-html="item2.subject"></p>
                </div>
                <!--
                <div class="interactions" style="min-height: 45px !important;">
                  <div v-if="item2.myApplyStatus">
                    <i>Başvurunuzun durumu:</i>
                    <h6>{{ item2.myApplyStatus.title }}</h6>
                  </div>
                  <template v-if="item2.amIHaveToCompleteMyApplyToThisProgram">
                      <template v-for="availableApply in item2.available_applies" :key="availableApply.uid">
                        <div class="d-flex justify-content-between mb-1">
                          <span v-if="availableApply.feed.answers.feed_program_apply_title !== undefined" class="programs-feed-title">
                          {{ availableApply.feed.answers.feed_program_apply_title.formItemReply }}
                          </span>
                          <span v-else-if="availableApply.feed.answers.feed_program_apply_description !== undefined" class="programs-feed-title">
                            {{ availableApply.feed.answers.feed_program_apply_description.formItemReply }}
                          </span>
                          <router-link :to="{  name: 'apply-form-detail', params: { eventFormToken: item2.event_form.token, applyUId: availableApply.apply.uid } }" class="btn btn-primary button_1">Başvuruya Devam Et</router-link>
                        </div>
                      </template>
                  </template>
                  <template v-if="!item2.amIHaveToCompleteMyApplyToThisProgram">
                    <div v-if="item2.canIApplyThisProgram == false">
                        <template v-if="item2.available_applies.length > 0">
                          <template v-for="availableApply in item2.available_applies" :key="availableApply.uid">
                            <div class="d-flex justify-content-between mb-1">
                              <span v-if="availableApply.feed.answers.feed_program_apply_title !== undefined" class="programs-feed-title">
                              {{ availableApply.feed.answers.feed_program_apply_title.formItemReply }}
                              </span>
                              <span v-else-if="availableApply.feed.answers.feed_program_apply_description !== undefined" class="programs-feed-title">
                                {{ availableApply.feed.answers.feed_program_apply_description.formItemReply }}
                              </span>
                            </div>
                          </template>
                        </template>
                      </div>
                  </template>
                 </div>
                -->
              </div>
               <div class="ps-4 pe-4 pt-3">
                  <hr class="purple m-0" style="opacity: 0.05;">
                </div>
              <div class="apply_area ps-4 pe-4 pt-3 d-flex align-items-center justify-content-between">
                  <div>
                      <div v-if="item2.is_feed_program">
                        <router-link :to="{  name: 'wall', params: { eventUId: item2.uid, eventFormToken: item2.event_form.token } }" class="purple font-size-base fw-bold btn border-0 bg-transparent">Detay</router-link>
                      </div>
                     <div v-if="!item2.is_feed_program">
                        <div v-if="!item2.amIHaveToCompleteMyApplyToThisProgram && item2.status">
                          <router-link :to="{  name: 'apply-form', params: { eventFormToken: item2.event_form.token } }" class="purple font-size-base fw-bold btn border-0 bg-transparent">Detay</router-link>
                        </div>
                        <div v-if="item2.amIHaveToCompleteMyApplyToThisProgram">
                          <div v-if="item2.available_applies.length > 0">
                            <div v-for="availableApply in item2.available_applies" :key="availableApply.uid">
                              <router-link :to="{  name: 'apply-form-detail', params: { eventFormToken: item2.event_form.token, applyUId: availableApply.apply.uid } }" class="purple font-size-base fw-bold btn border-0 bg-transparent">Başvuruya Devam Et</router-link>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="w-100 d-flex justify-content-end">
                    <div class="d-flex align-items-center apply_switch_1">
                      <router-link v-if="item2.is_feed_program" :to="{  name: 'wall', params: { eventUId: item2.uid, eventFormToken: item2.event_form.token } }">
                        <svg style="width:18px;fill:#402661;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/></svg>
                      </router-link>
                      <div v-if="!item2.is_feed_program">
                        <div v-if="!item2.amIHaveToCompleteMyApplyToThisProgram && item2.status">
                          <router-link :to="{  name: 'apply-form', params: { eventFormToken: item2.event_form.token } }">
                            <svg style="width:18px;fill:#402661;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/></svg>
                          </router-link>
                        </div>
                        <div v-if="item2.amIHaveToCompleteMyApplyToThisProgram">
                          <div v-if="item2.available_applies.length > 0">
                            <div v-for="availableApply in item2.available_applies" :key="availableApply.uid">
                              <router-link :to="{  name: 'apply-form-detail', params: { eventFormToken: item2.event_form.token, applyUId: availableApply.apply.uid } }">
                                <svg style="width:18px;fill:#402661;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/></svg>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '../../store/auth'
import { useProgramStore } from '@/store/program'

export default defineComponent({
  name: 'LoginView',
  data () { return {} },
  setup () {
    const authStore = useAuthStore()
    const programStore = useProgramStore()
    const isContentLoaded = ref(false)

    const route = useRoute()
    let programType = route.params.programType

    const statusFilter = ref('active')

    const load = () => {
      programType = route.params.programType
      isContentLoaded.value = false
      programStore.getPrograms(getFilteredType()).then(
        response => {
          isContentLoaded.value = true
        }
      )
    }

    onMounted(() => {
      load()
    })

    watch(route, () => {
      load()
    })

    watch(statusFilter, (newValue) => {
      isContentLoaded.value = false
      programStore.getPrograms(getFilteredType()).then(
        response => {
          isContentLoaded.value = true
        }
      )
    })

    const getFilteredType = () => {
      if (programType === 'thematic') return statusFilter.value + '_thematic_calls'
      else return statusFilter.value
    }

    return {
      authStore,
      programStore,
      isContentLoaded,
      statusFilter
    }
  },
  components: {
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>
@import 'src/styles/media_query.scss';
@import 'src/styles/bs5_theme.scss';
.hover-border{
  border: solid 5px transparent !important;
}
.hover-border:hover{
  border: solid 5px #fdb913 !important;
}
</style>
