<template>
  <layout-type-1>
    <div class="mb-4">
      <FormTextSlot form-text="Kayıt Ol"></FormTextSlot>
    </div>
      <Form></Form>
      <form @submit="onSubmit">
        <div class="form-outline mb-4 text-start">
          <label for="name" class="mb-1">Ad</label>
          <field id="name" name="name" placeholder="Adınızı Yazınız" class="form-control shadow-none" />
          <span class="red">{{ errors.name }}</span>
        </div>
        <div class="form-outline mb-4 text-start">
          <label for="surname" class="mb-1">Soyad</label>
          <field id="surname" name="surname" placeholder="Soyadınızı Yazınız" class="form-control shadow-none" />
          <span class="red">{{ errors.surname }}</span>
        </div>

        <div class="form-outline mb-4 text-start">
          <label for="email" class="mb-1">E-Posta</label>
          <field id="email" name="email" placeholder="E-Posta Adresinizi Yazınız" class="form-control shadow-none" />
          <span class="red">{{ errors.email }}</span>
          <span class="red" v-if="errorMessageField === 'email'">{{ errorMessage }}</span>
        </div>

        <div class="form-outline mb-4 text-start">
          <label for="password" class="mb-1">Şifre</label>
          <field id="password" name="password" placeholder="Şifrenizi Yazınız" class="form-control shadow-none" type="password"/>
          <span class="red">{{ errors.password }}</span>
          <span class="red" v-if="errorMessageField === 'password'">{{ errorMessage }}</span>
        </div>

        <div class="d-grid pt-1 mb-4 text-start col-4 offset-4">
          <ButtonSlot button-text="Kayıt Ol" style="color:#402661 !important;"></ButtonSlot>
        </div>
      </form>
  </layout-type-1>
</template>
<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useForm, Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { useAuthStore } from '../../store/auth'
import LayoutType1 from '../../layouts/LayoutType1.vue'
import ButtonSlot from '../../layouts/ButtonSlot.vue'
import FormTextSlot from '../../layouts/FormTextSlot'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'LoginView',
  data () { return {} },
  setup () {
    onMounted(() => {
      document.title = 'VakıfBank | Kayıt Ol'
    })
    const authStore = useAuthStore()
    const router = useRouter()
    const nameRegExp = /^([A-Za-z" "çğjöşüÇĞJÖŞÜİı]+)?$/
    const schema = yup.object().shape({
      name: yup.string().required('*Lütfen Adınızı Yazınız').matches(nameRegExp, '*Lütfen Geçerli Bir Ad Giriniz'),
      surname: yup.string().required('*Lütfen Soyadınızı Yazınız').matches(nameRegExp, '*Lütfen Geçerli Bir Soyad Giriniz'),
      email: yup.string().required('*Lütfen E-Posta Adresinizi Yazınız').email(),
      password: yup.string().required('*Lütfen Şifrenizi Yazınız').min(6, 'En az 6 karakter girmelisiniz')
    })
    const errorMessage = ref(null)
    const errorMessageField = ref(null)
    const resetErrorMessage = () => {
      errorMessage.value = ''
      errorMessageField.value = ''
    }
    const { handleSubmit, isSubmitting, errors } = useForm({
      validationSchema: schema
    })

    const onSubmit = handleSubmit((values, actions) => {
      resetErrorMessage()
      authStore.signUp(values).then(
        response => {
          if (response.status === 200) {
            router.push('/login')
          } else {
            if (response.response.data.messageType === 'formInput') {
              errorMessage.value = response.response.data.message
              errorMessageField.value = response.response.data.messageField
            }
          }
        }
      )
    })

    return {
      authStore,
      onSubmit,
      isSubmitting,
      errors,
      errorMessage,
      errorMessageField
    }
  },
  components: {
    Form,
    Field,
    LayoutType1,
    ButtonSlot,
    FormTextSlot
  },
  methods: {

  }
})
</script>
