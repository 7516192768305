<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card border-0 p-0">
          <div class="card-body p-0">
            <div class="row g-0">
              <div class="col-md-12 col-lg-6 d-none d-md-block">
                <div class="up_992_me">
                  <img src="@/assets/bonapply/FormImages/form_img_2.png" alt="login form" class="img-fluid w-100 rounded-10 down_992_margin_bottom down_992_padding_bottom_none"/>
                </div>
              </div>
              <div class="col-md-12 col-lg-5 d-flex align-items-center">
                <div class="card-body p-0">
                  <slot></slot>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
@import 'src/styles/media_query.scss';
@import 'src/styles/media_query.scss';
@import 'src/styles/bs5_theme.scss';
img{
  @include breakpoint(md2){
    padding-right: 0 !important;
    padding-bottom: 2rem !important;
  }
}
</style>
