<template>
  <LandingExternalView/>
</template>
<script>
import { defineComponent } from 'vue'
import LandingExternalView from '@/views/landing/LandingExternalView'

// import { useRouter } from 'vue-router'

export default defineComponent({
  setup () {
    // const router = useRouter()
    // router.push('/landing/in-house-view')
    return {
    }
  },
  components: {
    LandingExternalView
  }
})
</script>
