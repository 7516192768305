import { acceptHMRUpdate, defineStore } from 'pinia'
import ApplyService from '@/services/apply.service'
import { useRoute } from 'vue-router'

export const useApplyStore = defineStore({
  id: 'useApplyStore',
  state: () => ({
    getData: true,
    paging: {
      page: 1,
      length: 12
    },
    apply: null,
    applyId: 0,
    applyUId: 0,
    myApplies: null,
    myIdeas: null,
    myIdeaDrafts: null,
    myReturnedIdea: null,
    myEditableIdea: null,
    links: {
      file_show_base_url: process.env.VUE_APP_API_BASE_URL + '/api/v1/file/show/',
      file_download_base_url: process.env.VUE_APP_API_BASE_URL + '/api/v1/file/download/'
    },
    formItems: null,
    formErrors: null,
    agreements: null,
    eventForm: { title: '' },
    preview: null,
    isUpdating: false,
    isFeedProgram: false,
    route: useRoute()
  }),
  setup () {
  },
  actions: {
    resetPaging () {
      this.getData = true
      this.paging.page = 0
    },
    create () {
      let eventFormToken = '62986bc6472dd6.1342372762986bc6472e21.35206787'
      if (this.route.params.eventFormToken !== undefined && this.route.params.eventFormToken !== null) {
        eventFormToken = this.route.params.eventFormToken
      }
      const data = {
        apply_category_uid: null
      }

      this.formItems = null
      this.formErrors = null
      this.agreements = null

      return ApplyService.create(eventFormToken, data).then(
        response => {
          if (response.status === 200) {
            this.applyId = response.data.data.apply.id
          }
          return response
        }
      )
    },
    get (applyUid) {
      const data = {
        demo: null,
        export: null
      }

      this.formItems = null
      this.formErrors = null
      this.agreements = null

      return ApplyService.get(applyUid, data).then(
        response => {
          if (response.status === 200) {
            if (undefined !== response.data) {
              this.links = response.data.links
              this.isFeedProgram = response.data.data.is_feed_program

              if (undefined !== response.data.data.event_form) {
                // Form getirildi
                this.applyUId = applyUid
                this.applyId = response.data.data.apply_id
                this.applyId = response.data.data.apply.id
                this.eventForm = response.data.data.event_form
                const formItemsObject = response.data.data.event_form.forms_items_groups
                const _items = formItemsObject[Object.keys(formItemsObject)[0]].form_items
                const tmp = []
                Object.keys(_items).forEach(key => {
                  tmp[_items[key].place] = _items[key]
                })

                this.formItems = Object.assign({}, tmp)
                this.agreements = response.data.data.apply.agreements
              } else {
                // Form getirilmedi
                if (response.data.data.apply.complete === 1) {
                  alert('başvuru yapıldı sayfasına yönlendirme yapılacak')
                }
              }
            }
          }
          return response
        }
      )
    },
    setAnswer (itemId, itemValue) {
      this.isUpdating = true
      const data = {
        form_item_value: itemValue
      }

      return ApplyService.setAnswer(this.applyUId, itemId, data).then(
        response => {
          this.isUpdating = false
          return response
        }
      )
    },
    upload (itemId, file) {
      this.isUpdating = true
      const data = new FormData()
      data.append('file', file)
      return ApplyService.upload(this.applyUId, itemId, data).then(
        response => {
          this.isUpdating = false
          return response
        }
      )
    },
    sendApply () {
      this.formErrors = null
      return ApplyService.sendApply(this.applyUId).then(
        response => {
          if (response.status !== 200) {
            if (response.response.status === 400) {
              if (response.response.data.data.error_messages.length > 0) this.formErrors = []
              response.response.data.data.error_messages.forEach((errorMessage) => {
                this.formErrors[errorMessage.form_item_id] = errorMessage
              })
            }
          }
          return response
        },
        error => {
          return error
        }
      ).catch(
        error => {
          return error
        }
      )
    },
    getMyApplies () {
      if (this.getData) {
        this.paging.page = this.paging.page + 1
        return ApplyService.getMyApplies(this.paging).then(
          response => {
            if (this.paging.page === 1) {
              this.myApplies = response.data.data.applies
            } else {
              this.myApplies = [].concat.apply(this.myApplies, response.data.data.applies)
            }
            if (response.data.data.applies.length < this.paging.length) {
              this.getData = false
            }
            return response
          }
        )
      } else {
        return false
      }
    },
    getMyIdeas () {
      if (this.getData) {
        this.paging.page = this.paging.page + 1
        return ApplyService.getMyIdeas(this.paging).then(
          response => {
            if (this.paging.page === 1) {
              this.myIdeas = response.data.data.posts
            } else {
              this.myIdeas = [].concat.apply(this.myIdeas, response.data.data.posts)
            }
            if (response.data.data.posts.length < this.paging.length) {
              this.getData = false
            }
            return response
          }
        )
      } else {
        return false
      }
    },
    getMyIdeaDrafts () {
      return ApplyService.getMyIdeaDrafts().then(
        response => {
          this.myIdeaDrafts = response.data.data.posts
          return response
        }
      )
    },
    getMyReturnedIdea () {
      return ApplyService.getMyReturnedIdea().then(
        response => {
          this.myReturnedIdea = response.data.data.posts
          return response
        }
      )
    },
    getMyEditableIdea () {
      return ApplyService.getMyEditableIdea().then(
        response => {
          this.myEditableIdea = response.data.data.posts
          return response
        }
      )
    },
    deleteDraft (applyUId) {
      return ApplyService.deleteDraft(applyUId).then(
        response => {
          if (response.status === 200) {
            const tmp = []
            this.myIdeaDrafts.forEach((draft) => {
              if (draft.apply.uid !== applyUId) tmp.push(draft)
            })
            this.myIdeaDrafts = tmp
          }

          return response
        }
      )
    },
    getSingle (applyId) {
      return ApplyService.getSingle(applyId).then(
        response => {
          this.preview = response.data.data.feed
          return response
        }
      )
    },
    applyDetail () {
      const applyUId = this.route.params.applyUId
      return ApplyService.applyDetail(applyUId).then(
        response => {
          if (response.status === 200) {
            this.apply = response.data.data.apply
          }
          return response
        }
      )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useApplyStore, import.meta.hot))
}
