<template>
  <div class="wall-post mb-4">
    <div class="row">
        <div class="col-xl-7 col-lg-7 order-xl-1 order-lg-1 order-2 overflow-hidden word-break">

            <div class="mb-2 font-size-base"  v-if="layout=='my-ideas'">
                Fikir Durumu: <strong> {{ data.apply.status.title }} </strong>
            </div>
            <div class="mb-3 d-flex align-items-center">
                <div>
                    <p class="m-0 me-2 dark-gray font-size-base fw-bold text-capitalize">{{ data.apply.user.name }} {{ data.apply.user.surname }}</p>
                </div>
                <div>
                    <p class="m-0 me-2 dark-gray font-size-base" v-if="data.apply.completeDate" >{{ data.apply.completeDate }}</p>
                    <p class="m-0 me-2 dark-gray font-size-base" v-if="!data.apply.completeDate">{{ data.apply.updateDate }}</p>
                </div>
                <div class="d-flex align-content-center" v-if="layout=='my-ideas'">
                    <notification-list-3 :notifications="data.notifications"/>
                </div>
            </div>
            <div class="mb-3 cursor-pointer" v-if="data.answers.feed_program_apply_title" @click="onTitleClick()">
                <p class="m-0 black font-size-xxl fw-bold">{{data.answers.feed_program_apply_title.formItemReply}}</p>
            </div>
            <div class="mb-3" v-if="data.answers.feed_program_apply_description">
                <p class="m-0 black font-size-base" style="word-break: break-word !important;">
                    <read-more :text="data.answers.feed_program_apply_description.formItemReply"/>
                </p>
            </div>
            <div class="row mb-2">
                <div class="col-xl-4 col-lg-6 col-12 pe-0" style="width:max-content !important;">
                    <p class="m-0 black font-size-base">Fayda Tipi:</p>
                </div>
                <div class="col-xl-4 col-lg-6 col-12" style="width:max-content !important;">
                  <div class="px-4 rounded-20 mb-2" style="border:solid 1px black;width:max-content !important;">
                      <p class="m-0 black font-size-base">{{typeOfBenefit()}}</p>
                  </div>
                </div>
            </div>
            <div class="d-flex align-items-baseline">
              <div>
                <p class="black pe-1 text-decoration-none font-size-base">Dosya:</p>
              </div>
                <div>
                    <p class="m-0 black font-size-base">
                        <a :href="applyFile()" target="_blank" class="text-black text-decoration-none">{{applyFileName()}}</a>
                    </p>
                </div>
            </div>
            </div>
        <div class="col-xl-5 col-lg-5 order-xl-2 order-lg-2 order-1 cursor-pointer d-flex justify-content-end down_md_left" @click="onImageClick()">
        <img v-if="applyCoverImageFile()" :src="applyCoverImageFile()" alt="idea image" class="img-fluid w-100 down_992_margin_bottom rounded-10" style="max-height: 180px !important;width: max-content !important;"/>
        <!--<img v-if="!applyCoverImageFile()" src="@/assets/bonapply/no-image.svg" alt="idea image" class="img-fluid w-100 down_992_margin_bottom rounded-10"/>-->
        </div>
    </div>
    <div v-if="likeBox=true" class="row mt-3">
        <div class="col-xl-8 col-lg-8 col-12 pb-3" v-if="layout=='wall' || layout=='my-ideas' || layout=='singlePost'">
            <div class="d-flex align-items-center">
                <div v-if="canILike()">
                  <button v-if="!isLikedByMe" @click="onLikeClick()" type="btn" class="m-0 black font-size-lg fw-bold bg-transparent border-0 p-0 down_sm_fs1">Beğen</button>
                  <button v-if="isLikedByMe" @click="onUndoLikeClick()" type="btn" class="m-0 black font-size-lg fw-bold bg-transparent border-0 p-0 down_sm_fs1">Beğeniyi Geri Al</button>
                </div>
                <div v-if="data.activePartners.length" class="d-flex justify-content-center align-items-baseline black font-size-lg fw-bold down_sm_fs1">
                    <span  v-if="canILike()" class="dark-gray ms-3 me-3">│</span>
                    <i class="fa-solid fa-user me-2 mt-1"></i> {{ data.activePartners.length }} Ortak
                </div>
                <div v-if="canISendCollaborateRequest()" class="d-flex justify-content-center align-items-baseline black font-size-lg fw-bold down_sm_fs1">
                    <span v-if="isJoinButtonShow || isLeaveButtonShow" class="dark-gray ms-3 me-3">│</span>
                    <p v-if="isJoinButtonShow" class="m-0 black font-size-lg fw-bold pointer down_sm_fs1" @click="onJoinClick()">Fikre Ortak Ol</p>
                    <p v-if="isLeaveButtonShow" class="m-0 black font-size-lg fw-bold pointer down_sm_fs1" @click="onUndoJoinClick(layout)">Ortak Olmaktan Vazgeç</p>
                </div>
                <div v-if="isCollaborateStatusShow" class="d-flex justify-content-center align-items-baseline black font-size-lg fw-bold down_sm_fs1">
                    <span class="dark-gray ms-3 me-3">│</span>
                    <p class="m-0 black font-size-lg fw-bold down_sm_fs1">{{ collaborateStatusText }}</p>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-12" v-if="layout=='wall' || layout=='my-ideas' || layout=='singlePost'">
            <div class="d-flex align-items-center justify-content-end">
                <div class="d-flex align-items-center me-3">
                    <i class="fa-solid fa-thumbs-up me-2 dark-gray"></i>
                    <p class="m-0 dark-gray font-size-base down_sm_fs1">{{ likeCount }}</p>
                </div>
                <div class="d-flex align-items-center me-3">
                    <i class="bi bi-chat-left me-2 dark-gray"></i>
                    <p class="m-0 dark-gray font-size-base down_sm_fs1">{{ data.counts.comments }}</p>
                </div>
                <div class="d-flex align-items-center">
                    <i class="bi bi-eye-fill me-2 dark-gray"></i>
                    <p class="m-0 dark-gray font-size-base down_sm_fs1">{{ data.counts.views }}</p>
                </div>
            </div>
        </div>
        <div v-if="layout=='my-collaborate-ideas'" class="d-flex align-items-baseline black font-size-lg fw-bold down_sm_fs1">
          <p class="m-0 black font-size-lg fw-bold pointer down_sm_fs1" @click="onUndoJoinClick(layout)">Ortak Olmaktan Vazgeç</p>
        </div>
    </div>
    <div class="post-comments" v-if="layout=='wall' || layout=='my-ideas' || layout=='singlePost'">
        <comments-root parentEntity="Applies" :rootEntityId="data.apply.id"/>
    </div>
  </div>
  <hr class="mb-5"  v-if="layout=='wall' || layout=='my-ideas' || layout=='my-collaborate-ideas' || layout=='singlePost'">
</template>
<script>
import { defineComponent, ref } from 'vue'
import CommentsRoot from '@/components/bonapply/CommentsRoot'
import ReadMore from '@/components/bonapply/ReadMore'
import NotificationList3 from '@/components/bonapply/NotificationList3'
import { useCollaborateStore } from '@/store/collaborate'
import { useAuthStore } from '@/store/auth'
import { useConfigStore } from '@/store/config'
import { useLikeStore } from '@/store/like'
import { useAnalyticsStore } from '@/store/analytics'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'WallPost',
  props: {
    data: {},
    layout: {
      default: 'draft'
    }
  },
  data () { return {} },
  setup (props) {
    const router = useRouter()
    const configStore = useConfigStore()
    const likeStore = useLikeStore()
    const collaborateStore = useCollaborateStore()
    const authStore = useAuthStore()
    const analyticsStore = useAnalyticsStore()
    const likeCount = ref(props.data.counts.likes)
    const isLikedByMe = ref(props.data.isLikedByMe)
    const isJoinButtonShow = ref(false)
    const isLeaveButtonShow = ref(false)
    const isCollaborateStatusShow = ref(false)
    const collaborateStatusText = ref('')
    const canISendCollaborateRequest = () => {
      let result = true
      if (props.data.maxPartnership <= props.data.activePartners.length) result = false
      if (isThisMyIdea()) result = false
      return result
    }

    const typeOfBenefit = () => {
      if (props.data.answers.feed_program_apply_types_of_benefit) return props.data.answers.feed_program_apply_types_of_benefit.applyAnswerOptionsString
      else return '-'
    }

    const applyFile = () => {
      if (props.data.answers.feed_program_apply_file) { return configStore.downloadBasePath + props.data.answers.feed_program_apply_file.formItemReply } else return false
    }

    const applyFileName = () => {
      if (applyFile()) {
        if (props.data.answers.feed_program_apply_file) { return props.data.answers.feed_program_apply_file.applyAnswerFileName } else return ''
      } else {
        return ''
      }
    }

    const applyCoverImageFile = () => {
      if (props.data.answers.feed_program_apply_cover_image_file) {
        return configStore.imageBasePath + props.data.answers.feed_program_apply_cover_image_file.formItemReply
      } else {
        return false
      }
    }

    const onLikeClick = () => {
      likeStore.like('Applies', props.data.apply.id).then(
        response => {
          if (response.status === 200) {
            likeCount.value++
            isLikedByMe.value = true
          }
        }
      )
    }
    const onUndoLikeClick = () => {
      likeStore.undoLike('Applies', props.data.apply.id).then(
        response => {
          if (response.status === 200) {
            likeCount.value--
            isLikedByMe.value = false
          }
        }
      )
    }
    const canILike = () => {
      return !isThisMyIdea()
    }
    const isThisMyIdea = () => {
      return (props.data.apply.user.username === authStore.user || props.data.apply.user.email === authStore.user)
    }

    const onImageClick = () => {
      redirectTotDetail()
    }

    const onTitleClick = () => {
      redirectTotDetail()
    }

    const redirectTotDetail = () => {
      router.push({ name: 'feed', params: { applyUId: props.data.apply.uid } }).then(() => {
        analyticsStore.sendView('apply', props.data.apply.id)
      })
    }

    const isCollaborateStatus = () => {
      if (props.data.myPartnershipRequestToThePost) { return true } else { return false }
    }
    const getCollaborateStatusText = () => {
      if (!props.data.myPartnershipRequestToThePost) return ''
      return props.data.myPartnershipRequestToThePost.statusText
    }

    const joinButtonStatus = () => {
      if (props.data.myPartnershipRequestToThePost) { return false } else { return true }
    }
    const leaveButtonStatus = () => {
      if (props.data.myPartnershipRequestToThePost) {
        if (props.data.myPartnershipRequestToThePost.status === 0) {
          return true
        } else {
          return false
        }
      } else { return false }
    }

    const onJoinClick = () => {
      collaborateStore.requestToJoin(props.data.apply.id).then(
        response => {
          if (response.status === 200) {
            isJoinButtonShow.value = false
            isLeaveButtonShow.value = true
            isCollaborateStatusShow.value = true
            collaborateStatusText.value = response.data.data.myPartnershipRequestToThePost.statusText
          }
        }
      )
    }

    const onUndoJoinClick = (layout) => {
      collaborateStore.leaveRequestToJoin(props.data.apply.id).then(
        response => {
          if (response.status === 200) {
            isJoinButtonShow.value = true
            isLeaveButtonShow.value = false
            isCollaborateStatusShow.value = false
            collaborateStatusText.value = ''
            if (layout === 'my-collaborate-ideas') {
              window.location.reload()
            }
          }
        }
      )
    }

    collaborateStatusText.value = getCollaborateStatusText()
    isCollaborateStatusShow.value = isCollaborateStatus()
    isJoinButtonShow.value = joinButtonStatus()
    isLeaveButtonShow.value = leaveButtonStatus()
    return {
      onJoinClick,
      onUndoJoinClick,
      collaborateStore,
      authStore,
      typeOfBenefit,
      applyFile,
      applyFileName,
      applyCoverImageFile,
      onLikeClick,
      onUndoLikeClick,
      canISendCollaborateRequest,
      collaborateStatusText,
      canILike,
      likeCount,
      isLikedByMe,
      isThisMyIdea,
      onImageClick,
      onTitleClick,
      isJoinButtonShow,
      isLeaveButtonShow,
      isCollaborateStatusShow
    }
  },
  components: {
    CommentsRoot,
    NotificationList3,
    ReadMore
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>
@import 'src/styles/bs5_theme.scss';
@import 'src/styles/media_query.scss';
</style>
