<template>
  <layout-type-1>
    <div class="mb-4">
      <FormTextSlot form-text="Aktivasyon İçin Kayıt Ol"></FormTextSlot>
    </div>
      <Form></Form>
      <form @submit="onSubmit">
        <div class="form-outline mb-4 text-start">
          <label for="name" class="mb-1">Ad</label>
          <field id="name" name="name" placeholder="Adınızı Yazınız" class="form-control shadow-none" />
          <span>{{ errors.name }}</span>
        </div>
        <div class="form-outline mb-4 text-start">
          <label for="surname" class="mb-1">Soyad</label>
          <field id="surname" name="surname" placeholder="Soyadınızı Yazınız" class="form-control shadow-none" />
          <span>{{ errors.surname }}</span>
        </div>

        <!--
        <div class="form-outline mb-4 text-start">
          <label for="email" class="mb-1">E-Posta (Aktivasyon maili aldığınız e-posta adresi)</label>
          <field id="email" name="email" placeholder="E-Posta Adresinizi Yazınız" class="form-control shadow-none" />
          <span>{{ errors.email }}</span>
        </div>
        -->

        <div class="form-outline mb-4 text-start">
          <label for="password" class="mb-1">Şifre</label>
          <field id="password" name="password" placeholder="Şifrenizi Yazınız" class="form-control shadow-none" type="password"/>
          <span>{{ errors.password }}</span>
        </div>

        <div class="d-grid pt-1 mb-4 text-start col-4 offset-4">
          <ButtonSlot button-text="Kayıt Ol"></ButtonSlot>
        </div>
      </form>
  </layout-type-1>
</template>
<script>
import { defineComponent, onMounted } from 'vue'
import { useForm, Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { useAuthStore } from '../../store/auth'
import LayoutType1 from '../../layouts/LayoutType1.vue'
import ButtonSlot from '../../layouts/ButtonSlot.vue'
import FormTextSlot from '../../layouts/FormTextSlot'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'LoginView',
  data () { return {} },
  setup () {
    onMounted(() => {
      document.title = 'VakıfBank | Aktivasyon'
    })
    const authStore = useAuthStore()
    const router = useRouter()
    const schema = yup.object().shape({
      name: yup.string().required('*Lütfen Adınızı Yazınız'),
      surname: yup.string().required('*Lütfen Soyadınızı Yazınız'),
      // email: yup.string().required('*Lütfen Aktivasyon Maili Aldığınız E-posta Adresini Yazınız').email(),
      password: yup.string().required('*Lütfen Şifrenizi Yazınız')
    })

    const { handleSubmit, isSubmitting, errors } = useForm({
      validationSchema: schema
    })

    const onSubmit = handleSubmit((values, actions) => {
      authStore.activationSignUp(values).then(
        response => {
          if (response.status === 200) {
            if (response.data.redirect !== []) {
              if (response.data.redirect.endpoint === 'panel') {
                // Kayıt+Aktivasyon sonrası panele yönlendiriliyor
                window.location.href = response.data.redirect.url
              } else {
                router.push('/login')
              }
            } else {
              router.push('/login')
            }
          }
        }
      )
    })

    return {
      authStore,
      onSubmit,
      isSubmitting,
      errors
    }
  },
  components: {
    Form,
    Field,
    LayoutType1,
    ButtonSlot,
    FormTextSlot
  },
  methods: {

  }
})
</script>
