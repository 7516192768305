import { BaseService } from './BaseService'
import { useAuthStore } from '@/store/auth'
const COOKIE_POLICY = '/v1/static-page/cookies_policy'

class CookiesPolicyService extends BaseService {
  getCookiesPolicyInfo () {
    const sectionKey = (undefined !== useAuthStore().getToken() && useAuthStore().getToken() !== null) ? 'inHouseUserCookiesPolicy' : 'externalUserCookiesPolicy'
    return this.get(COOKIE_POLICY + '?sectionKey=' + sectionKey)
  }
}

export default new CookiesPolicyService()
