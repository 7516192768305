<template>
  <div class="container">
    <div class="row">
      <nav class="navbar navbar-expand-lg navbar-light py-5">
  <div class="container p-0">
    <a href="/">
      <img v-if="companyStore.company" src="@/assets/LeadImages/landing-img9.svg" class="img-fluid" :alt="companyStore.company.title" width="140">
    </a>
    <div class="d-flex">
       <div class="dropdown menu_dropdown up_992_display_none d-flex align-items-center"  v-if="authStore.isLoggedIn">
        <button class="btn dropdown-toggle d-flex pe-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
           <i class="fa-solid fa-user-large ms-2"></i>
        </button>
        <ul class="dropdown-menu border-0 bg-white rounded-20 shadow" aria-labelledby="dropdownMenuButton1">
          <li><router-link :to="{ name: 'my-ideas' }" class="dropdown-item px-4">{{ authStore.user }}</router-link></li>
           <hr class="m-1 ms-4 me-4">
          <li><router-link :to="{ name: 'my-ideas' }" class="dropdown-item px-4">Fikirlerim</router-link></li>
          <hr class="m-1 ms-4 me-4">
          <li><router-link :to="{ name: 'my-profile' }" class="dropdown-item px-4">Profilim</router-link></li>
          <hr class="m-1 ms-4 me-4">
          <li><router-link :to="{ name: 'logout' }" class="dropdown-item px-4">Çıkış Yap</router-link></li>
        </ul>
    </div>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="#navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    </div>
    <div class="collapse navbar-collapse mt-2" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto mb-lg-0">
         <li class="nav-item me-4 d-none" v-if="!authStore.isLoggedIn">
           <router-link :to="{ name: 'register' }" class="nav-link" aria-current="page">Kayıt Ol</router-link>
        </li>
        <li class="nav-item" v-if="!authStore.isLoggedIn && route.name !== 'login'">
          <form class="d-flex" v-if="!authStore.isLoggedIn">
            <router-link :to="{ name: 'login' }" type="button" class="btn rounded-10 login_button fw-bold pe-5 ps-5 pt-3 pb-3" :disabled="isSubmitting" aria-current="page">Giriş Yap</router-link>
          </form>
        </li>
        <li class="nav-item me-5"  v-if="authStore.isLoggedIn">
          <router-link :to="{ name: 'programs' }" class="nav-link" aria-current="page">Programlar</router-link>
        </li>
        <li class="nav-item me-5"  v-if="authStore.isLoggedIn">
          <router-link :to="{ name: 'Blog' }" class="nav-link">Blog</router-link>
        </li>
        <li class="nav-item me-5"  v-if="authStore.isLoggedIn">
          <router-link :to="{ name: 'contact-view' }" class="nav-link">İletişim</router-link>
        </li>
      </ul>
      <MainSearch/>
      <div class="dropdown menu_dropdown  down_992_display_none d-flex"  v-if="authStore.isLoggedIn">
      <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle border-0 pe-0 position-relative" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="bi bi-bell"></i>
          <i class="bi bi-dot position-absolute yellow-dot"></i>
        </button>
        <ul class="dropdown-menu notification_menu p-2 border-0 shadow bg-white" aria-labelledby="dropdownMenuButton1">
          <div class="row mb-3">
            <div class="col-6 d-flex align-items-center">
            </div>
            <div class="col-6 d-flex align-items-center justify-content-end">
              <router-link :to="{ name: 'notifications-view' }" class="nav-link text-start font-size-lg down_md_pl_0 ps-0" style="font-size: 0.75rem !important;text-decoration:underline;">Hepsini Gör</router-link>
            </div>

            <div class="row mb-5 ps-3 pe-3">
              <div class="col-12 mb-3">
                <notification-list-2/>
              </div>
            </div>

          </div>
      </ul>
    </div>
      <button class="btn dropdown-toggle d-flex pe-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
        {{ authStore.user }}
         <i class="fa-solid fa-user-large ms-2"></i>
      </button>
      <ul class="dropdown-menu w-100 border-0 bg-white rounded-20 shadow" aria-labelledby="dropdownMenuButton1">
        <li><router-link :to="{ name: 'my-ideas' }" class="dropdown-item px-4">Fikirlerim</router-link></li>
            <hr class="m-1 ms-4 me-4">
            <li><router-link :to="{ name: 'my-profile' }" class="dropdown-item px-4">Profilim</router-link></li>
            <hr class="m-1 ms-4 me-4">
            <li><router-link :to="{ name: 'logout' }" class="dropdown-item px-4">Çıkış Yap</router-link></li>
        </ul>
    </div>
        </div>
      </div>
      </nav>
    </div>
  </div>
</template>
<script>
import MainSearch from '@/components/bonapply/MainSearch'
import { defineComponent } from 'vue'
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import { useAuthStore } from '../../store/auth'
import { useRoute, useRouter } from 'vue-router'
import { useCompanyStore } from '@/store/company'
import NotificationList2 from '@/components/bonapply/NotificationList2'

export default defineComponent({
  name: 'LoginView',
  data () { return {} },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const authStore = useAuthStore()
    const schema = yup.object().shape({
      username: yup.string().required('*E-Posta Adresinizi Yazınız').email(),
      password: yup.string().required('*Parolanızı Yazınız').min(8)
    })

    const companyStore = useCompanyStore()
    companyStore.getCompanyInfo()

    const { handleSubmit, isSubmitting, errors } = useForm({
      validationSchema: schema
    })

    const onSubmit = handleSubmit((values, actions) => {
      authStore.login(values).then(
        response => {
          router.push('/programs')
        }
      )
    })

    return {
      authStore,
      onSubmit,
      isSubmitting,
      errors,
      companyStore,
      route
    }
  },
  components: {
    MainSearch,
    NotificationList2
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>
.navbar-toggler:focus{box-shadow: none;}
@import 'src/styles/media_query.scss';
.dropdown-item:hover{
  border-radius:10px !important;
}
</style>
