<template>
      <div class="col-xl-1 col-lg-1 col-1">
        <div
          class="
            pagination-circle
            bg-primary
            rounded-circle
            d-flex
            align-items-center
            justify-content-center
            fw-bold
          "
          style="height: 36px; width: 36px; color: #ffffff;"
        >
          {{ data.rank }}
        </div>
      </div>
      <div class="col-xl-11 col-lg-11 col-11">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-6 col-12">
            <p class="m-0 black font-size-2xl fw-bold down_md2_font1 text-capitalize">
              {{ data.user.name+" "+data.user.surname }}
            </p>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-12">
            <div class="d-flex align-items-center justify-content-end">
              <div class="d-flex align-items-center me-3">
                <i class="fa-solid fa-thumbs-up me-2 dark-gray"></i>
                <p class="m-0 dark-gray font-size-base">{{ data.counts.likes }}</p>
              </div>
              <div class="d-flex align-items-center me-3">
                <i class="bi bi-chat-left me-2 dark-gray"></i>
                <p class="m-0 dark-gray font-size-base">{{ data.counts.comments }}</p>
              </div>
              <div class="d-flex align-items-center">
                <i class="bi bi-eye-fill me-2 dark-gray"></i>
                <p class="m-0 dark-gray font-size-base">{{ data.counts.views }}</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p class="m-0">
            <button
              class="
                btn
                black
                font-size-base
                fw-bold
                d-flex
                align-items-center
                p-0
                mt-4
                m-0
              "
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="'#leaderboard-collapse-item-' + data.rank"
            >
              Gözden Geçir
              <i class="fa-solid fa-chevron-down font-size-xs ms-2"></i>
            </button>
          </p>

          <div class="collapse" :id="'leaderboard-collapse-item-' + data.rank">
            <div v-for="post in data.feeds" :key="post">
              <LeaderBoardDropdown :data="post"></LeaderBoardDropdown>
            </div>
          </div>
        </div>
      </div>
</template>
<style lang="scss" scoped>
@import "src/styles/media_query.scss";
</style>
<script>
import { defineComponent } from 'vue'
import LeaderBoardDropdown from '../../components/bonapply/LeaderBoardDropdown'
export default defineComponent({
  name: 'LeaderBoard',
  props: {
    data: {}
  },
  data () { return {} },
  setup (props) {
    return {

    }
  },
  components: {
    LeaderBoardDropdown
  },
  methods: {

  }
})
</script>
