<template>
     <div class="container mb-3 mt-3 down_sm_p0">
        <div class="ps-4 pe-4 pt-4 pb-4">
            <hr class="purple m-0">
        </div>
        <div class="row">
            <div class="col-xl-9 col-12  down_md2_margin_bottom pe-4 ps-4 down_md_pe_0 down_md_pl_0 down_md_pe_0">
                <div class="row down_sm_flex_center">
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 down_sm_flex_center down_sm_pb_1">
                    <a href="#">
                       <img src="@/assets/LeadImages/landing-img9.svg" alt="vakifbank logo" class="down_md2_width" width="93"/>
                    </a>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 d-flex align-items-center down_sm_flex_center p-0 down_md_display_center">
                        <a href="/faq-view" class="text-decoration-none purple down_md2_font2 down_sm_fs2 fw-light down_sm_fs3">S.S.S</a>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 d-flex align-items-center down_sm_flex_center p-0 down_md_display_center">
                        <a href="#aboutUs" class="text-decoration-none purple down_md2_font2 down_sm_fs2 fw-light down_sm_fs3">Hakkımızda</a>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 d-flex align-items-center down_sm_flex_center p-0 down_md_display_center">
                        <a href="/kvkk" class="text-decoration-none purple down_md2_font2 down_sm_fs2 fw-light down_sm_fs3">KVKK</a>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 d-flex align-items-center down_sm_flex_center p-0 down_md_display_center">
                        <a href="/cookies-policy" class="text-decoration-none purple down_md2_font2 down_sm_fs2 fw-light down_sm_fs3">Çerez Politikası</a>
                    </div>
                </div>
            </div>
             <div class="col-xl-3 col-12  d-flex align-items-center justify-content-end font-size-xxs">
                © 2022 Türkiye Vakıflar Bankası T.A.O.
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {}
})
</script>
<style lang="scss" scoped>
@import 'src/styles/media_query.scss';
@import 'src/styles/bs5_theme.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500;700&display=swap');
*{
    font-family: 'Roboto', sans-serif !important;
}
@media only screen and (max-width: $screen-md-min){
  .down_md_pl_0{
    padding-left: 0 !important;
  }
  .down_md_pe_0{
    padding-right: 0 !important;
  }
}

</style>
