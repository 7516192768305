<template>
<div class="border-0 hover-border rounded-10 p-4" style="background-color:#f8f8f8!important;">
  <!--
     <div class="mb-2" v-if="post.videoPath != ''">
        <video width="400" height="190" controls style="max-width: 100%" class="rounded-10">
          <source :src="post.videoPath" type="video/mp4">
        </video>
    </div>
    -->
    <div class="mb-2" v-if="post.coverImg != ''">
        <img :src="post.coverImg" class="img-fluid w-100 h-100 rounded-10">
    </div>
    <div class="mb-3" style="height:50px;overflow:hidden;">
        <h4 class="font-size-xl fw-bold" style="color:#402661 !important;">{{ post.title }}</h4>
        </div>
        <div class="mb-3 thin_scrollbar" style="height:165px;color:#402661!important;overflow:auto !important; overflow-x: hidden !important;">
        <p class="card-text font-size-lg" style="color:#402661 !important;" v-html="post.content">

        </p>
        </div>
        <div>
        <p class="card-text black font-size-lg">
        {{ post.release_date }}
        </p>
        </div>
        <!--
        <div class="d-flex">
        <p class="card-text black font-size-lg me-2">YAZAN:</p>
        <p class="card-text black font-size-lg">{{ post.author.name }} {{ post.author.surname }}</p>
        </div>
         -->
         <!--
        <div>
        <a :href="'/blog/' + post.slug" class="black font-size-lg fw-bold text-underline">OKU</a>
        </div>
         -->
         <div class="ps-4 pe-4 pt-3">
                <hr class="purple m-0" style="opacity: 0.05;">
            </div>
            <div class="row d-flex align-items-center ps-4 pe-4 pt-3">
                <div class="col-6">
                     <a :href="'/blog/' + post.slug" class="purple font-size-base fw-bold text-decoration-none">Detay</a>
                </div>
                <div class="col-6 d-flex align-items-center justify-content-end">
                  <a :href="'/blog/' + post.slug">
                  <svg style="width:18px;fill:#402661;cursor:pointer;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
                  </a>
                </div>
          </div>
</div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BlogCard',
  props: {
    post: {}
  },
  data () { return {} },
  setup (props) {
    return {

    }
  },
  components: {

  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>
@import 'src/styles/bs5_theme.scss';
@import 'src/styles/media_query.scss';
.hover-border{
  border: solid 5px transparent !important;
}
.hover-border:hover{
  border: solid 5px #fdb913 !important;
}
</style>
