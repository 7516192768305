import { BaseService } from './BaseService'
const FAQ_PATH = '/v1/faq/'

/* eslint-disable */
class FaqService extends BaseService {
  // Sık Sorulan Sorular Bilgilerini Almak
  getFaqInfo () {
    // const pageKey = (undefined !== useAuthStore().getToken() && useAuthStore().getToken() !== null) ? 'in_house_user' : 'external_user'
    const pageKey = 'all'
    return this.get(FAQ_PATH + pageKey + '/list')
  }
  // End
}
export default new FaqService()
/* eslint-disable */
