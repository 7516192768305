<template>
<div class="container">
    <div class="row">
      <div class="col-12 col-md-3">
        <div class="d-flex align-items-start down_md_block">
          <div class="nav flex-column nav-pills me-3 pb-5 down_sm_mr_none" id="v-pills-tab" role="tablist" aria-orientation="vertical" style="flex-direction: inherit !important;">
            <div v-if="newIdeaButtonStatus">
              <new-idea/>
            </div>
            <router-link :to="{ name: 'wall', params: { eventUId: route.params.eventUId, eventFormToken: route.params.eventFormToken } }" class="nav-link nav-link-2 text-start px-0 font-size-lg w-100">Program</router-link>
            <div class="nav-link nav-link-2 text-start font-size-lg down_md_pl_0 ps-0" style="font-weight: bold">Liderlik Tablosu</div>
            <router-link :to="{ name: 'populars-for-event', params : { eventUId:route.params.eventUId, eventFormToken: route.params.eventFormToken } }" class="nav-link nav-link-2 text-start px-0 font-size-lg w-100">En Popüler</router-link>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-9">
        <div>
          <div class="row w-100 mb-2">
            <div class="col-9 d-flex align-items-center">
              <h5 class="m-0">{{ programStore.active_event_title }}</h5>
            </div>
          </div>
          <div class="row w-100 mb-5">
              <div class="col-9 d-flex align-items-center">
                  <h5 class="m-0">Liderlik Tablosu</h5>
              </div>
              <div class="col-3 text-end">
                  <img src="@/assets/bonapply/LeadImages/cup.png" alt="cup image" class="img-fluid" width="66"/>
              </div>
          </div>
          <div class="shadow-lg rounded-20 py-4 px-5 down_sm_padding_1 mb-4" v-for="leader in leaderStore.leaderboards_list" :key="leader">
            <div class="row">
              <leader-board-card :data="leader"></leader-board-card>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import { defineComponent, onMounted, ref } from 'vue'
import LeaderBoardCard from '../../components/bonapply/LeaderBoardCard.vue'
import { useLeaderStore } from '@/store/leader'
import { useProgramStore } from '@/store/program'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'LeaderBoardView',
  data () { return {} },
  setup () {
    onMounted(() => {
      document.title = 'VakıfBank | Liderlik Tablosu'
    })
    const route = useRoute()
    const leaderStore = useLeaderStore()
    const programStore = useProgramStore()
    const newIdeaButtonStatus = ref(false)
    leaderStore.getLeaderInfo().then(
      response => {
        if (response.data.data.event.title !== '') {
          newIdeaButtonStatus.value = !!(response.data.data.event.status)
          programStore.setActiveEventTitle(response.data.data.event.title)
          programStore.setActiveEventCoverSubject(response.data.data.event.cover_subject)
          programStore.setActiveEventSubject(response.data.data.event.subject)
          document.title = 'VakıfBank | ' + response.data.data.event.title + ' Liderlik Tablosu'
        }
      }
    )

    return {
      leaderStore,
      programStore,
      newIdeaButtonStatus,
      route
    }
  },
  components: {
    LeaderBoardCard
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>
@import 'src/styles/media_query.scss';
</style>
