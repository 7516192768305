<template>
  <layout-type-1>
    <div class="mb-4">
      <FormTextSlot form-text="Giriş Yap"></FormTextSlot>
    </div>
    <Form></Form>
    <form @submit="onSubmit">
      <div class="form-outline mb-4 text-start">
        <label for="username" class="mb-1">Kullanıcı Adı</label>
        <field id="username" name="username" placeholder="Sicil Numaranızı Yazınız" class="form-control shadow-none" />
        <span class="red bs">{{ errors.username }}</span>
        <span class="red" v-if="errorMessageField === 'username'">{{ errorMessage }}</span>
      </div>
      <div class="form-outline mb-4 text-start">
        <label for="password" class="mb-1">Şifre</label>
        <field id="password" name="password" placeholder="Parolanızı Yazınız" class="form-control shadow-none" type="password"/>
        <span class="red">{{ errors.password }}</span>
        <span class="red" v-if="errorMessageField === 'password'">{{ errorMessage }}</span>
      </div>
      <div class="d-grid pt-1 mb-4 text-start col-4 offset-4">
        <ButtonSlot button-text="Giriş Yap"></ButtonSlot>
      </div>
      <div class="text-center mt-4">
          <router-link to="/forgot-password" class="purple2 text-decoration" style="color:#402661 !important;font-weight:500!important;">*Şifremi Unuttum</router-link>
        </div>
      <div class="form_footer d-flex text-start pt-3">
        <p class="me-2 fw-normal black">Hesabınız yok mu? <a href="/register" class="text-decoration" style="color:#402661!important;font-weight:500!important;">Kayıt Ol</a></p>
      </div>
    </form>
  </layout-type-1>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useForm, Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { useAuthStore } from '../../store/auth'
import { useRouter } from 'vue-router'
import LayoutType1 from '../../layouts/LayoutType1.vue'
import ButtonSlot from '../../layouts/ButtonSlot.vue'
import FormTextSlot from '../../layouts/FormTextSlot'

export default defineComponent({
  name: 'LoginView',
  data () { return {} },
  setup () {
    onMounted(() => {
      document.title = 'VakıfBank | Giriş Yap'
    })
    const router = useRouter()
    const authStore = useAuthStore()
    const errorMessage = ref(null)
    const errorMessageField = ref(null)
    const resetErrorMessage = () => {
      errorMessage.value = ''
      errorMessageField.value = ''
    }
    const schema = yup.object().shape({
      username: yup.string().required('*Kullanıcı Adınızı Yazınız'),
      password: yup.string().required('*Şifrenizi Yazınız').min(8)
    })

    const { handleSubmit, isSubmitting, errors } = useForm({
      validationSchema: schema
    })

    const onSubmit = handleSubmit((values, actions) => {
      resetErrorMessage()
      authStore.login(values).then(
        response => {
          if (response.status === 200) {
            if (response.data.redirect && response.data.redirect !== []) {
              if (response.data.redirect.endpoint === 'renew_password') {
                // Şifre yenileme sayfasına yönlendiriyor
                const token = response.data.redirect.additionalData.reset_token
                router.push('/renew-password/' + token)
              }
            } else {
              const redirectToUrl = localStorage.getItem('redirectToUrl')
              if (redirectToUrl === undefined || redirectToUrl === null) {
                router.push('/programs')
              } else {
                router.push(redirectToUrl)
              }
            }
            localStorage.removeItem('redirectToUrl')
          } else {
            if (response.response.data.messageType === 'formInput') {
              errorMessage.value = response.response.data.message
              errorMessageField.value = response.response.data.messageField
            }
          }
        }
      )
    })

    return {
      authStore,
      onSubmit,
      isSubmitting,
      errors,
      errorMessage,
      errorMessageField
    }
  },
  components: {
    Form,
    Field,
    LayoutType1,
    ButtonSlot,
    FormTextSlot
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>
@import 'src/styles/bs5_theme.scss';
</style>
