import { BaseService } from './BaseService'
const BLOG = '/v1/blog/'

class BlogService extends BaseService {
  getPosts (filter, paging) {
    // const pageKey = (undefined !== useAuthStore().getToken() && useAuthStore().getToken() !== null) ? 'in_house_user' : 'external_user'
    const pageKey = 'all'
    return this.get(BLOG + pageKey + '/list' + '?page=' + paging.page + '&length=' + paging.length, filter)
  }

  getRecentPosts () {
    // const pageKey = (undefined !== useAuthStore().getToken() && useAuthStore().getToken() !== null) ? 'in_house_user' : 'external_user'
    const pageKey = 'all'
    return this.get(BLOG + pageKey + '/recently-added-list')
  }

  getPopularPosts () {
    // const pageKey = (undefined !== useAuthStore().getToken() && useAuthStore().getToken() !== null) ? 'in_house_user' : 'external_user'
    const pageKey = 'all'
    return this.get(BLOG + pageKey + '/popular_list')
  }

  getSingle (slug) {
    // const pageKey = (undefined !== useAuthStore().getToken() && useAuthStore().getToken() !== null) ? 'in_house_user' : 'external_user'
    const pageKey = 'all'
    return this.get(BLOG + pageKey + '/find/' + slug)
  }
}

export default new BlogService()
