<template>
   <div class="mb-3">
       <carousel :items-to-show="1" :wrap-around="true" :autoplay="10000">
          <slide v-for="sectionItem in section.items" :key="sectionItem">
            <div class="container h-100" v-if="sectionItem.title != null">
              <div @click="goTolink(sectionItem.video,sectionItem.link)" class="row w-100" :class="( sectionItem.link != '' || sectionItem.video != '' ) ? 'cursor-pointer' : ''">
                <div class="col-xl-6 col-lg-6 col-md-7 col-12 m-auto h-100" style="text-align:left !important;">
                  <div>
                      <p class="m-0 text-left font-size-3xl purple fw-bold line-height down_md2_font3">{{sectionItem.title}}</p>
                  </div>
                  <div>
                      <p class="m-0 text-left font-size-base purple" v-html="sectionItem.content"></p>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-5 col-12 h-100">
                  <img :src="sectionItem.coverImg" class="img-fluid down_md2_font4 w-100 rounded-10" alt="landing image">
                </div>
              </div>
            </div>
            <div class="container-fluid" v-if="sectionItem.title == null">
              <div @click="goTolink(sectionItem.video,sectionItem.link)" class="row w-100 m-0" :class="( sectionItem.link != '' || sectionItem.video != '' ) ? 'cursor-pointer' : ''">
                <div class="col-12 p-0">
                  <img :src="sectionItem.coverImg" class="img-fluid down_md2_font4 w-100 rounded-10" alt="landing image">
                </div>
              </div>
            </div>
          </slide>
          <template #addons>
          <navigation />
          <pagination />
          </template>
      </carousel>
  </div>
  <!-- Video Modal -->
  <div class="modal fade" id="videoModalModal" tabindex="-1" aria-labelledby="videoModalLabel" aria-hidden="true" data-bs-backdrop='static'>
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-4 rounded-30 border-0">
          <div class="modal-header border-0 p-1">
            <button @click="closeVideo()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center" id="videoModalBody"></div>
        </div>
      </div>
    </div>
  <button type="button" data-bs-toggle="modal" data-bs-target="#videoModalModal" id="openVideoModal" class="d-none"></button>
</template>
<script>
import { defineComponent } from 'vue'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default defineComponent({
  props: {
    section: {}
  },
  setup (props) {
    const goTolink = (video, link) => {
      if (video != null && video !== '') {
        document.getElementById('videoModalBody').innerHTML = '<video width="400" height="230" controls style="max-width: 100%"><source src="' + video + '" type="video/mp4"></video>'
        document.getElementById('openVideoModal').click()
      } else {
        if (link != null && link !== '') {
          window.location.href = link
        }
      }
    }
    const closeVideo = (video, link) => {
      document.getElementById('videoModalBody').innerHTML = ''
    }

    return {
      goTolink,
      closeVideo
    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  }
})
</script>
<style lang="scss" scoped>
@import 'src/styles/media_query.scss';
@import 'src/styles/bs5_theme.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500;700&display=swap');
*{
    font-family: 'Roboto', sans-serif !important;
}
</style>
