<template>
<div class="main">
        <span @click="onOpenClick()">
            <i class="fa fa-clock text-primary open"></i>
        </span>
        <div v-if="isOpen" class="history-container shadow rounded p-3">
            <span @click="onCloseClick()">
                <i  class="fa fa-times close"></i>
            </span>

            <div class="row mb-5">
                <template v-for="notification in notifications" :key="notification">
                <div class="step completed d-flex justify-content-between">
                    <div class="v-stepper">
                    <div class="circle  d-flex align-items-center justify-content-center">
                        <i class="fa-solid fa-circle-check font-size-xxs black"></i>
                    </div>
                    <div class="line"></div>
                    </div>
                    <div class="content w-100 pe-3">
                        <p class="font-size-sm black" style="word-break:break-word !important;" v-html="notification.message"></p>
                        <p class="light-gray font-size-sm d-flex justify-content-between"><span>{{ notification.date.date }}</span> <span>{{ notification.date.time }}</span></p>
                    </div>
                </div>
                </template>
            </div>
        </div>
</div>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'NotificationList3',
  props: {
    notifications: []
  },
  data () { return {} },
  setup (props) {
    const isOpen = ref(false)

    const onOpenClick = () => {
      isOpen.value = true
    }

    const onCloseClick = () => {
      isOpen.value = false
    }

    return {
      onOpenClick,
      onCloseClick,
      isOpen
    }
  },
  components: {

  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>
@import 'src/styles/bs5_theme.scss';
@import 'src/styles/media_query.scss';
.main {
    margin-top: -8px;
}
.history-container {
  position: absolute;
  width: 300px;
  height: auto;
  background: white;
  z-index: 1000;
}
.close {
    cursor: pointer;
    float: right;
}
.open {
    cursor: pointer;
}
</style>
