<template>
  <div class="home">
    <div class="row">
      <div class="col-12 mb-5" style="display: none !important">
      <a href="/">
        <img :src="companyStore.company.logo" class="img-fluid" alt="company_images" width="164" height="51">
      </a>
    </div>
    <div class="col-12">
      <div v-html="companyStore.company.cover_letter"></div>
    </div>
    </div>

  </div>
</template>
<script>
import { defineComponent, onMounted } from 'vue'
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import { useAuthStore } from '../../store/auth'
import { useRouter } from 'vue-router'
import { useCompanyStore } from '@/store/company'

export default defineComponent({
  name: 'LoginView',
  data () { return {} },
  setup () {
    onMounted(() => {
      document.title = 'VakıfBank'
    })
    const router = useRouter()
    const authStore = useAuthStore()
    const schema = yup.object().shape({
    })
    const companyStore = useCompanyStore()
    companyStore.getCompanyInfo()

    const { handleSubmit, isSubmitting } = useForm({
      validationSchema: schema
    })

    const onSubmit = handleSubmit((values, actions) => {
      authStore.login(values).then(
        response => {
          router.push('/programs')
        }
      )
    })

    return {
      authStore,
      onSubmit,
      isSubmitting,
      companyStore
    }
  },
  components: {
  },
  methods: {
  }
})
</script>

<style lang="scss" scoped>
@import 'src/styles/media_query.scss';
@import 'src/styles/bs5_theme.scss';
</style>
