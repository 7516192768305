<template>
    <div class="container mb-5 down_md_pb_none" style="padding-bottom:3rem;">
        <div class="row mb-5">
            <div class="col-12 text-center">
                <p class="font-size-3xl yellow fw-bold line-height down_md2_font3">
                  {{section.title}}
                </p>
                <p class="m-0 font-size-xl purple" v-html="section.content"></p>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-6 col-12 mb-3" v-for="sectionItem in section.items" :key="sectionItem">
                <div class="bg_color rounded-10 div_hover">
                    <div class="pt-4 pb-4 ps-4 pe-4 d-flex justify-content-center">
                        <img :src="sectionItem.coverImg" alt="landing image" class="img-fluid w-100 rounded-10">
                    </div>
                    <div class="ps-3 pe-3 landing_title_height text-center">
                        <p class="font-size-2xl purple fw-bold down_md2_font4 d-flex justify-content-center w-100 text-ellipsis">
                            {{sectionItem.title}}
                        </p>
                    </div>
                    <div class="pb-3">
                        <div class="text-center ps-4 pe-4 landing_content_height">
                           <p class="m-0 font-size-base brown_color thin_scrollbar" style="height: 190px; overflow: hidden auto !important;" v-html="sectionItem.content"></p>
                        </div>
                    </div>
                    <div class="ps-4 pe-4">
                        <hr class="purple m-0" style="opacity: 0.05;">
                    </div>
                    <div class="apply_area ps-4 pe-4 d-flex align-items-center justify-content-between pb-4 pt-4">
                        <div>
                            <a :href="sectionItem.link" class="purple font-size-base fw-bold text-decoration-none apply_text">Başvur</a>
                        </div>
                        <div class="w-100 d-flex justify-content-end">
                             <a :href="sectionItem.link">
                                <svg style="width:18px;fill:#402661;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/></svg>
                             </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    section: {}
  },
  setup (props) {}
})
</script>
<style lang="scss" scoped>
@import 'src/styles/media_query.scss';
@import 'src/styles/bs5_theme.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500;700&display=swap');
*{
    font-family: 'Roboto', sans-serif !important;
}
</style>
