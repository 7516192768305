import { BaseService } from './BaseService'
const EVENTS_PATH = '/v1/event/'

class ProgramsService extends BaseService {
  getPrograms (type = 'active', tag) {
    // const pageKey = (undefined !== useAuthStore().getToken() && useAuthStore().getToken() !== null) ? 'in_house_user' : 'external_user'
    const pageKey = 'all'
    if (tag != null) {
      return this.get(EVENTS_PATH + pageKey + '/list/' + type + '?tag=' + tag)
    } else {
      return this.get(EVENTS_PATH + pageKey + '/list/' + type)
    }
  }
}

export default new ProgramsService()
