<template>
    <div>
        <p v-if="!isReadMoreOn" v-snip="{ lines: 3, onSnipped }" v-html="text"></p>
        <p v-if="isReadMoreOn" v-html="text"></p>
        <div v-if="hasEllipsis && !isReadMoreOn">
          <span @click="onReadMoreClick">Daha Fazla Göster<svg class="ps-1" style="height:11px;" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg></span>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'ReadMore',
  props: {
    text: null
  },
  text () { return '' },
  setup (props) {
    const hasEllipsis = ref(false)
    const isReadMoreOn = ref(false)
    const onSnipped = (newState) => {
      hasEllipsis.value = newState.hasEllipsis
    }

    const onReadMoreClick = () => {
      isReadMoreOn.value = true
    }

    return {
      onSnipped,
      hasEllipsis,
      onReadMoreClick,
      isReadMoreOn
    }
  },
  components: {

  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>
.read-more {
    cursor: pointer
}
</style>
