<template>
    <div class="main-search" v-click-away="onClickAway" v-if="authStore.user">
        <form class="d-flex me-5 align-items-center down_sm_mr_none" >
        <div class="input-group me-2 border rounded-pill  down_sm_full_width" style="width:max-content;">
        <input v-model="inputValue" @keyup="onKeyUp" @click="onInputClick" class="search form-control border-0 ps-0 ms-4 shadow-none" type="search" placeholder="Ara" aria-label="Ara" style="width:auto!important;">
        <button class="btn"> <i class="fa-solid fa-magnifying-glass"></i></button>
        </div>
        </form>
        <div class="search-results rounded shadow" v-if="isOpen" style="z-index:9 !important;">
            <!-- Tabs navs -->
            <ul class="nav nav-tabs mb-3 border-bottom-0" id="ex1" role="tablist">
            <li class="nav-item" role="presentation">
                <a
                @click="onSearchTabClick(0)"
                class="nav-link pointer font-size-sm dark-gray"
                :class="isTabActive(0)"
                id="search-tab-1"
                role="tab"
                >Genel Arama</a
                >
            </li>
            <li class="nav-item" role="presentation">
                <a
                @click="onSearchTabClick(1)"
                class="nav-link pointer font-size-sm dark-gray"
                :class="isTabActive(1)"
                id="search-tab-2"
                role="tab"
                >Etiket</a
                >
            </li>
            <li class="nav-item" role="presentation">
                <a
                @click="onSearchTabClick(2)"
                class="nav-link pointer font-size-sm dark-gray"
                :class="isTabActive(2)"
                id="search-tab-3"
                role="tab"
                >Ad-Soyad</a
                >
            </li>
            </ul>
            <!-- Tabs navs -->

            <ul class="p-3 list-unstyled pt-0" v-if="activeTabIndex == 0">
                <div v-if="searchStore.results.length == 0">Sonuç bulunamıyor</div>
                <li class="border-bottom mb-3 pb-1" style="word-break: break-all !important; cursor: pointer"  v-for="result in searchStore.results" :key="result" @click="onSearchResultItemClick(result)">
                  <span  class="black fw-bold text-capitalize" v-if="result.type==='apply'">
                    {{ result.answers.feed_program_apply_title.formItemReply }}
                  </span>
                  <span class="black fw-bold text-capitalize"  v-if="result.type==='blog'">
                    {{ result.blog.title }}
                  </span>
                  <span class="black fw-bold text-capitalize"  v-if="result.type==='event'">
                    {{ result.event.title }}
                  </span>
                </li>
            </ul>
            <ul class="p-3 list-unstyled pt-0" v-if="activeTabIndex == 1">
                <div v-if="searchStore.tagResults.length == 0">Sonuç bulunamıyor</div>
                <li class="border-bottom mb-3 pb-1" style="word-break: break-all !important;" v-for="tag in searchStore.tagResults" :key="tag">
                    <div class="form-check">
                      <input @change="onTagsChange($event)" v-model="checkedTagIds"  class="form-check-input" type="checkbox" :value="tag.id" :id="'main-search-tag-' + tag.id">
                      <label class="form-check-label black fw-bold text-capitalize" :for="'main-search-tag-' + tag.id">
                        {{ tag.title }}
                      </label>
                    </div>

                </li>
            </ul>
            <ul class="p-3 list-unstyled pt-0" v-if="activeTabIndex == 2">
                <div v-if="searchStore.userResults.length == 0">Sonuç bulunamıyor</div>
                <li class="border-bottom mb-3 pb-1" style="word-break: break-all !important;" v-for="user in searchStore.userResults" :key="user">
                    <div class="form-check">
                      <input @change="onUsersChange($event)" v-model="checkedUserIds"  class="form-check-input" type="checkbox" :value="user.id" :id="'main-search-user-' + user.id">
                      <label class="form-check-label black fw-bold text-capitalize" :for="'main-search-user-' + user.id">
                        {{ user.name + " "  + user.surname  }}
                      </label>
                      <br>
                      <span style="font-size: 12px">{{ user.email }}</span>
                    </div>

                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { defineComponent, onMounted, ref, watch } from 'vue'
import { useSearchStore } from '@/store/search'
import { useAuthStore } from '@/store/auth'
import { useFeedStore } from '@/store/feed'
import { useRouter } from 'vue-router'

export default defineComponent({
  setup () {
    const searchStore = useSearchStore()
    const authStore = useAuthStore()
    const feedStore = useFeedStore()
    const activeTabIndex = ref(0)
    const inputValue = ref(null)
    const isOpen = ref(false)
    const checkedTagIds = ref([])
    const checkedUserIds = ref([])
    const router = useRouter()

    onMounted(() => {
      inputValue.value = searchStore.searchTerm
    })

    const search = (value) => {
      checkedTagIds.value = []
      checkedUserIds.value = []
      feedStore.resetFilter()
      feedStore.resetPosts()
      if (activeTabIndex.value === 0) searchStore.search(value)
      else if (activeTabIndex.value === 1) searchStore.tagSearch(value)
      else if (activeTabIndex.value === 2) searchStore.userSearch(value)
    }
    const onKeyUp = (event) => {
      const value = event.target.value
      searchStore.searchTerm = value
      search(value)
    }

    const onSearchTabClick = (index) => {
      checkedTagIds.value = []
      checkedUserIds.value = []
      feedStore.resetFilter()
      feedStore.resetPaging()
      feedStore.resetPosts()
      feedStore.feed(true, 1)
      activeTabIndex.value = index
      search(inputValue.value)
    }

    const isTabActive = (index) => {
      if (index === activeTabIndex.value) return 'active'
      else return ''
    }

    const onClickAway = (event) => {
      isOpen.value = false
    }

    const onInputClick = () => {

    }

    const onSearchResultItemClick = (result) => {
      if (result.type === 'apply') {
        router.push({ name: 'feed', params: { applyUId: result.apply.uid } })
      } else if (result.type === 'event') {
        if (result.event.is_feed_program) {
          router.push({ name: 'wall', params: { eventUId: result.event.uid, eventFormToken: result.event.event_form.token } })
        } else if (result.type === 'event') {
          router.push('/programs')
        }
      } else if (result.type === 'blog') {
        router.push('/blog/' + result.blog.slug)
      }
      isOpen.value = false
    }

    const onTagsChange = (event) => {
      router.push({ name: 'search-result' }).then(() => {
        feedStore.filter.tagIds = checkedTagIds.value
        feedStore.resetPaging()
        feedStore.resetPosts()
        feedStore.feed(true, 1)
      })
    }

    const onUsersChange = (event) => {
      router.push({ name: 'search-result' }).then(() => {
        feedStore.filter.userIds = checkedUserIds.value
        feedStore.resetPaging()
        feedStore.resetPosts()
        feedStore.feed(true, 1)
      })
    }

    watch(inputValue, () => {
      if (inputValue.value === '') isOpen.value = false
      else isOpen.value = true
    })

    watch(feedStore.filter, () => {
      const query = {}
      Object.keys(feedStore.filter).forEach(filterKey => {
        const filterItem = feedStore.filter[filterKey]
        if (Array.isArray(filterItem) && filterItem.length > 0) {
          query[filterKey] = filterItem.join(',')
        } else if (query[filterKey] !== '') {
          query[filterKey] = filterItem
        }
      })
      router.push({ path: '', query })
    })

    return {
      searchStore,
      feedStore,
      onKeyUp,
      onSearchTabClick,
      activeTabIndex,
      isTabActive,
      inputValue,
      onClickAway,
      isOpen,
      onInputClick,
      authStore,
      onSearchResultItemClick,
      onTagsChange,
      checkedTagIds,
      onUsersChange,
      checkedUserIds
    }
  }
})
</script>
