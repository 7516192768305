<template>
<div class="comment-input" v-if="showMe">
    <Form></Form>
    <form @submit="onSubmit">
        <div class="form-outline mb-2 text-start">
        <field id="message" name="message" maxLength="500" placeholder="Yorum yaz..." class="form-control shadow-none" v-model="message" />
        <span>{{ errors.message }}</span>
        </div>
        <button type="submit" class="btn btn-secondary btn-block pull-right button_4">Gönder</button>
    </form>
</div>
</template>
<script>
import { defineComponent, toRefs, ref, watch } from 'vue'
import { useCommentStore } from '../../store/comment'
import { useForm, Form, Field } from 'vee-validate'
import * as yup from 'yup'

export default defineComponent({
  name: 'CommentItem',
  props: {
    show: null,
    parentId: null
  },
  data () { return {} },
  setup (props, context) {
    const { show, parentId } = toRefs(props)
    const message = ref('')
    const showMe = ref(show.value)
    const commentStore = useCommentStore()
    const schema = yup.object().shape({
      message: yup.string().required('*Lütfen yorumunuzu yazın').max(500, 'Maximum 500 karakter uzunluğunda yorum yapabilirsiniz.')
    })

    const { handleSubmit, errors } = useForm({
      validationSchema: schema
    })

    watch(() => props.show, (first, seccond) => {
      if (!showMe.value) showMe.value = true
    })

    const onSubmit = handleSubmit((values, actions) => {
      let data = {}
      if (parentId.value === undefined) {
        data = {
          message: values.message,
          parentEntity: commentStore.activeRootEntity,
          parentEntityId: commentStore.activeRootEntityId
        }
      } else {
        data = {
          message: values.message,
          parentId: parentId.value,
          parentEntity: 'Comment',
          parentEntityId: parentId.value
        }
      }

      commentStore.add(data).then(
        response => {
          // showMe.value = false
          context.emit('onWriteCommentComplete', {})
          message.value = ''
          const data = {
            parentEntity: commentStore.activeRootEntity,
            parentEntityId: commentStore.activeRootEntityId
          }
          commentStore.get(data)
        }
      )
    })

    return {
      commentStore,
      onSubmit,
      errors,
      message,
      showMe
    }
  },
  components: {
    Form,
    Field
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>
@import 'src/styles/bs5_theme.scss';
@import 'src/styles/vakifbank-style.scss';
</style>
